import React from 'react';
import { observer } from 'mobx-react';
import { Field, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';


@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.setContragentId = this.setContragentId.bind(this);
	}

	setContragentId(record) {
		record['contragentId'] = this.props.contragentId;
		return  record;
	}
	render () {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);
		return <div className="fixed-page">
			<ModelEdit beforeSave={this.setContragentId} model={store.model[modelName]} id={id} path={path} title='Банковские реквезиты'>
				<div className='row'>
					<div className='col-4'>
						<Field property='personName' label='ФИО' />
						<Field property='personRole' label='Должность' />
						<Field property='comment' label='Комментарий'>
							<textarea />
						</Field>
						<Field property='phone' label='Телефон' />
						<Field property='email' label='E-mail' />
					</div>
				</div>
			</ModelEdit>
		</div>;
	}
};