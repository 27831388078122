export default {
	translation: {
		export: 'Экспорт',
		import: 'Импорт',
		clean: 'Сбросить',
		all: 'Все',
		day: 'День',
		week: 'Неделя',
		month: 'Месяц',
		year: 'Год',
		quarter: 'Квартал',
		defaultTitle: 'SmartERP',
		name: 'Название',
		number: 'Номер',
		unnamed: 'Без названия',
		type: 'Тип',
		firstName: 'Имя',
		lastName: 'Фамилия',
		middleName: 'Отчество',
		fullName: 'Ф.И.О.',
		doubleClickToEdit: 'Двойной щелчок для редактирования',
		description: 'Описание',
		addDescription: 'Добавить описание',
		add: 'Добавить',
		finance: 'Финансы',
		loading: 'Загрузка...',
		information: 'Информация',
		attachments: 'Прикрепленные файлы',
		files: 'Файлы',
		uploadFiles: 'Загрузить файлы',
		deleteFile: 'Удалить файл',
		uploadingFiles: 'Загружаемые файлы',
		uploadError: 'Ошибка загрузки',
		uploadingFile: 'Загрузка файла',
		uploadSuccess: 'Загрузка завершена',
		rejectedFiles: 'Загрузка запрещена',
		selectedCount: 'Выбрано элементов',
		dragDropHint: 'Перетащите сюда файлы или нажмите чтобы выбрать файлы',
		editHint: 'Двойной клик для редактирования',
		editHintMobile: 'Долгий тап для редактирования',
		management: 'Управление',
		sum: 'Сумма',
		tax: 'Налог',
		date: 'Дата',
		rub: '₽',
		administration: 'Администрирование',
		usersAndRoles: 'Пользователи и права',
		settings: 'Настройки',
		percent: 'Процент',
		price: 'Цена',
		paid: 'Оплачено',
		phone: 'Телефон',
		create: 'Создать',
		search: 'Поиск',
		more: 'еще',
		moreEllipsis: 'Еще...',
		next: 'дальше',
		nextEllipsis: 'Дальше...',
		notFound: 'Не найдено',
		nothingFound: 'Ничего не найдено',
		total: 'всего',
		edit: 'Редактировать',
		save: 'Сохранить',
		saving: 'Сохранение...',
		saved: 'Сохранено',
		savedAt: 'Сохранено в',
		cancel: 'Отмена',
		delete: 'Удалить',
		clear: 'Очистить',
		confirmDelete: 'Удалить?',
		deleting: 'Удаление...',
		deleted: 'Удалено',
		updatedAt: 'Обновлено',
		error: 'Ошибка',
		ok: 'ОК',
		yes: 'Да',
		no: 'Нет',
		projects: 'Проекты',
		dashboard: 'Управление',
		board: 'Задачи',
		agent: 'Агент',
		clients: 'Клиенты',
		persons: 'Персоны',
		budgets: 'Бюджеты',
		budgetRoles: 'Участники бюджетов',
		noSelected: 'не выбрано',
		noCreate: 'создание объекта здесь не разрешено',
		payments: 'Платежи',
		paymentInfo: 'Платежные реквизиты',
		realm: 'Область',
		status: 'Статус',
		color: 'Цвет',
		avatar: 'Аватар',
		notSet: 'не задано',
		priority: 'Приоритет',
		created: 'Создано',
		modify: 'Изменить',
		modified: 'Изменено',
		username: 'Пользователь',
		users: 'Пользователи',
		roles: 'Роли',
		acls: 'Права',
		perPage: 30,
		itemsPerPage: 'Кол-во на странице',
		invalidId: 'Некорректный ID',
		modelAccessDenied: 'Нет доступа к модели',
		private: 'Приватность',
		startDate: 'Начало',
		dueDate: 'Завершение',
		roleMappings: 'Назначения ролей',
		contragentTypes: 'Типы контрагентов',
		documents: 'Документы',
		'Element does not exist': 'Объект не найден',
		'Element updated': 'Объект обновален',
		'Element created': 'Объект создан',
		'Element deleted': 'Объект удален',
		percentLeft: 'Доля компании',
		authorization: 'Авторизация',
		login: 'Войти',
		logout: 'Выйти',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		NO_SESSION_CAPTCHA: 'Не сгенерирован новый код',
		EMPTY_CAPTCHA: 'Не введен код',
		INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		Unauthorized: 'Необходимо авторизоваться',
		required: 'Необходимо заполнить поле',
		datePeriod: {
			select: 'Выберите период',
			specified: 'Произвольный период',
			currentMonth: 'Текущий месяц',
			currentQuarter: 'Текущий квартал',
			currentYear: 'Текущий год',
			lastMonth: 'Прошлый месяц',
			lastQuarter: 'Прошлый квартал',
			lastYear: 'Прошлый год',
			all: 'Всё время',
		},
		auth: {
			title: 'Авторизация',
			login: 'Войти',
			logout: 'Выйти',
			register: 'Зарегистрироваться',
			email: 'E-mail',
			lastName: 'Фамилия',
			firstName: 'Имя',
			middleName: 'Отчество',
			password: 'Пароль',
			order: 'Получить доступ',
			enterEmail: 'Введите E-mail',
			enterLogin: 'Введите логин',
			enterPassword: 'Введите пароль',
			repeatPassword: 'Повторите пароль',
			repeat: 'Повторите',
			minPasswordLength: 'Минимальная длина пароля',
			invalidEmail: 'Некорректный E-mail',
			emailExists: 'Такой E-mail уже зарегистрирован',
			registrationSuccess: 'На ваш E-mail ($email) была выслана ссылка для подтверждения регистрации.',
			noMatch: 'Пароли не совпадают',
			enterCode: 'Введите код',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
			limitExceeded: 'Превышен лимит попыток',
			tryAgain: 'Введите логин / пароль',
			registration: 'Регистрация',
			captcha: 'Код',
		},
		placeholder: {
			search: 'Поиск...',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
		},
		sign: {
			in: 'Авторизация',
			out: 'Выход',
		},
		password: {
			hint: 'Пароль...',
			field: 'Пароль',
			input: 'Введите пароль',
			new: 'Введите новый пароль',
			repeat: 'Повторите пароль',
			newHint: 'Оставьте поля пустыми, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают',
			match: 'Пароли совпадают',
		},
		editor: {
			enterURL: 'Введите URL:',
		},
		document: {
			title: 'Документ',
			plural: 'Документы',
			search: 'Фильтр + поиск по наименованию',
			total: 'Всего документов',
			card: {
				name: 'Наименование',
				number: 'Номер',
				type: 'Тип',
				status: 'Статус',
				contragents: 'Контрагенты',
				labels: 'Метки',
				responsible: 'Ответственный',
				tabs: {
					info: 'Общая информация',
					projects: 'Проекты',
				},
			},
		},
		documentType: {
			title: 'Тип документа',
			plural: 'Типы документов',
		},
		documentStatus: {
			title: 'Статус документа',
			plural: 'Статусы документов',
		},
		project: {
			title: 'Проект',
			plural: 'Проекты',
			create: 'Новый проект',
			addRole: 'Добавить участника',
			addTask: 'Добавить задачу',
			labels: 'Метки проекта',
			name: 'Название проекта',
			description: 'Описание проекта',
			projectList: 'Состояние проекта:',
			projectListShort: 'Состояние:',
			tasks: 'Задачи',
			lists: 'Состояния задач',
			budgets: 'Бюджеты',
			members: 'Участники',
			history: 'История',
			info: 'Информация',
			deleteMsg: 'Вы собираетесь удалить проект! Вы уверены?',
			notFound: 'Проект не найден',
			listMode: 'Список',
			kanbanMode: 'Канбан',
			count: 'Проектов',
			tasksCount: 'Задач',
			openedCount: 'Открытых',
			closedCount: 'Закрытых',
			filterHint: 'Поиск по id, названию',
			search: 'Поиск проекта',
			total: 'Всего проектов',
			mode: 'Вид',
			closedPlural: 'закрытые',
			activePlural: 'активные',
			all: 'все',
			sortByUpdated: 'Недавно обновленные',
			sortByNew: 'Сначала новые',
			owner: 'Проект создал(а)',
			searchByMember: 'Поиск по участнику',
		},
		client: {
			title: 'Контрагент',
			plural: 'Контрагенты',
			create: 'Новый контрагент',
			personName: 'Контактное лицо',
			personRole: 'Должность',
			contacts: 'Контактные лица',
			addContact: 'Добавить',
			orders: 'Заказы контрагента',
			removeContact: 'Убрать из контактных лиц контрагента?',
			removeContactWarning:
				'Убрать из контактных лиц контрагента?\nЭто также уберет этот контакт из всех заказов этого контрагента.\nЗаказов с этим контактом: ',
			noContacts: 'Контактные лица не найдены',
			new: 'Новый контрагент',
			onlyAvailable: 'Только контактные лица контрагента',
		},
		contact: {
			searchHint: 'Поиск по Ф.И.О. / логину / email',
			new: 'Новый контакт',
			emptyName: 'Заполните хотя бы одно поле (фамилия / имя / отчество / логин)',
			add: 'Добавить контактное лицо',
		},
		person: {
			title: 'Персона',
			plural: 'Персоны',
			create: 'Новая персона',
			choose: 'Выберите персону',
		},
		budget: {
			title: 'Бюджет',
			plural: 'Бюджеты',
			addRole: 'Добавить участника',
			addPayment: 'Добавить платеж',
			paid: 'Поступления в бюджет',
			create: 'Новый бюджет',
			name: 'Название бюджета',
			emptyName: 'Не задано название',
		},
		budgetRole: {
			title: 'Участник',
			plural: 'Участники',
			create: 'Новый участник',
			role: 'Роль в бюджете',
			paid: 'Выплаты',
		},
		payment: {
			title: 'Платеж',
			plural: 'Платежи',
			contragent: 'Контрагент',
			date: 'Дата',
			total: 'Сумма',
			payer: 'Отправитель',
			recipient: 'Получатель',
			vat: 'Сумма НДС',
			vatRate: 'Ставка НДС',
			payerText: 'Текст отправителя',
			recipientText: 'Текст получателя',
			selectProject: 'Выберите проект',
			selectPayer: 'Выберите отправителя',
			selectRecipient: 'Выберите получателя',
			selectBankAccount: 'Выберите счёт',
			selectCashFlow: 'Выберите статью',
			selectedBankAccounts: 'Выбрано счетов',
			selectedCashFlows: 'Выбрано статей',
			selectPayerBankAccount: 'Выберите счет отправителя',
			selectRecipientBankAccount: 'Выберите счет получателя',
			selectCurrency: 'Выберите валюту',
			bankAccount: 'Счет',
			recipientBankAccount: 'Банковский счет получателя',
			payerBankAccount: 'Банковский счет отправителя',
			number: 'Номер',
			paymentId: 'Идентификатор',
			purpose: 'Назначение',
			priority: 'Приоритет',
			responsiblePerson: 'Ответственное лицо',
			providerStatus: 'Статус составителя',
			KBKindicator: 'Показатель КБК',
			okato: 'ОКАТО',
			baseIndicator: 'Показатель основания',
			periodIndicator: 'Показатель периода',
			numberIndicator: 'Показатель номера',
			dateIndicator: 'Показатель даты',
			typeIndicator: 'Показатель типа',
			budgetTransfer: 'Перечисления в бюджет',
			paid: 'Оплачено',
			contract: 'Договор',
			allBankAccounts: 'Все счета',
			allCashFlows: 'Все статьи',
			allContragents: 'Все контрагенты',
			allProjects: 'Все проекты',
		},
		contract: {
			title: 'Договор',
			plural: 'Договора',
			date: 'Дата',
			contragent: 'Контрагент',
			organization: 'Оргиназиация',
			name: 'Наименование',
			number: 'Номер',
			contractType: 'Тип',
			conventionalUnit: 'Расчеты в условных единицах',
		},
		projectRole: {
			title: 'Роль в проекте',
			plural: 'Роли в проектах',
			short: 'Роль',
			create: 'Новая роль в проекте',
			choose: 'Выберите роль в проекте',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			create: 'Новый пользователь',
			phone: 'Телефон',
			email: 'E-mail',
			emptyEmail: 'Введите E-mail',
			invalidEmail: 'Некорректный E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			loginOrEmail: 'Логин / E-mail',
			password: 'Пароль',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			profile: 'Профиль',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал(а) задачу',
			telegram: 'Telegram ID',
			deletedUser: 'Неизвестный пользователь',
			emailAlreadyExists: 'Пользователь с таким E-mail уже существует',
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			name: 'Название',
			description: 'Описание',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: 'Модель',
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенные роли',
			create: 'Новое назначение роли',
		},
		projectMembers: 'Участники проекта',
		projectMember: {
			title: 'Участник',
			plural: 'Участники',
			create: 'Новый участник',
			choose: 'Выберите или персону или роль в проекте',
			same: 'Такой участник проекта уже есть',
			comment: 'Комментарий',
		},
		labels: 'Метки',
		label: {
			title: 'Метка',
			plural: 'Метки',
			create: 'Новыя метка',
		},
		tasks: 'Задачи',
		task: {
			title: 'Задача',
			plural: 'Задачи',
			create: 'Новая задача',
			delete: 'Удалить задачу',
			confirmDelete: 'Вы уверены?',
			name: 'Название задачи',
			description: 'Описание задачи',
			hasDescription: 'У этой задачи есть описание',
			createDescription: 'Добавить описание',
			priority: 'Приоритет',
			person: 'Назначена',
			startDate: 'Начало задачи',
			dueDate: 'Дедлайн',
			assignedTo: 'Назначено',
			notAssigned: 'Не назначена',
			actions: 'Действия с задачей',
			labels: 'Метки задачи',
			updatedAt: 'Обновлено',
			closed: 'закрыта',
			active: 'активна',
			status: 'Состояние',
			boardList: 'Состояние задачи',
			closedPlural: 'закрытые',
			activePlural: 'активные',
			all: 'все',
			All: 'Все',
			private: 'Приватная',
			subject: 'задачу',
			noProject: 'Задача без проекта!',
			sortByUpdated: 'Сначала новые',
		},
		history: {
			title: 'История задачи',
			created: 'создал(а) задачу',
			comments: 'Комментарии',
			leftComment: 'оставил(а) комментарий',
			updated: 'обновил(а)',
			appointed: 'назначил(а) задачу на',
			appointedSelf: 'назначил(а) задачу на себя',
			appointedNull: 'убрал(а) назначение задачи',
			status: 'перевел(а) задачу в статус',
			priority: 'изменил(а) приоритет задачи',
			private: 'сделал(а) задачу приватной',
			public: 'сделал(а) задачу публичной',
			startDate: 'установил(а) начало задачи на',
			dueDate: 'установил(а) дедлайн на',
			initial: 'изначальная версия',
			prev: 'предыдущая версия',
			updatedTo: 'обновленная версия',
			deleteFile: 'удалил(а) файл',
			empty: 'не задано',
			nulled: 'значение удалено',
		},
		boardLists: 'Состояния задач',
		boardList: {
			title: 'Состояние задачи',
			plural: 'Состояния задач',
			create: 'Новое состояние',
			name: 'Название состояния',
			selectListToMove: 'Куда перенести задачи из этого списка?',
			moveTo: 'Перенести задачи в ',
			choose: 'Выберите список',
			movingTasksTo: 'Идет перемещение задач в список: ',
			closing: 'Закрывающий',
			closingShort: 'Закрывающий',
			all: 'все',
			projectDefault: 'По умолчанию в проекте',
		},
		projectLists: 'Состояния проектов',
		projectList: {
			title: 'Состояние проекта',
			plural: 'Состояния проекта',
			create: 'Новое состояние',
			name: 'Название состояния',
			closing: 'Закрывающий',
			default: 'По умолчанию',
		},
		taskLabel: {
			title: 'Метка задачи',
			labels: 'Метки задачи',
			plural: 'Метки задач',
			create: 'Новая метка задачи',
		},
		file: {
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploadError: 'Ошибка загрузки',
			fileTooBig: 'Слишком большой файл',
			uploading: 'загрузка',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
		},
		validate: {
			empty: 'Пустое значение',
		},
		kanban: {
			searchHint: 'Поиск по номеру, названию, Ф.И.О., меткам',
		},
		analytics: {
			title: 'Аналитика',
			plural: 'Аналитика',
			projects: 'По проектам',
		},
		comment: {
			title: 'Комментарий',
			postTitle: 'Написать комментарий',
			placeholder: 'Текст комментария',
			post: 'Отправить',
			confirmDelete: 'Удалить комментарий?',
		},
		filter: {
			all: 'все',
			none: 'нет',
			equals: 'равно',
			notEquals: 'не равно',
			greater: 'больше чем',
			greaterOrEqual: 'больше или равно',
			less: 'меньше чем',
			lessOrEqual: 'меньше или равно',
			contains: 'содержит',
			doesNotContain: 'не содержит',
			startsWith: 'начинается с',
			endsWith: 'заканчивается на',
		},
		ui: {
			theme: 'Тема',
			themes: {
				default: 'По умолчанию',
				dark: 'Тёмная',
			},
		},
		sales: {
			title: 'Продажи',
		},
		feature: {
			title: 'Характеристика',
			plural: 'Характеристики',
			type: 'Тип',
			values: 'Значения списка',
			string: 'Текст',
			number: 'Число',
			boolean: 'Да / Нет',
			list: 'Список',
			required: 'Обязательное',
			weight: 'Порядок',
			products: 'Виды продукции',
		},
		priceList: {
			title: 'Прайс-лист',
			positions: 'Позиций',
		},
		priceCategory: {
			title: 'Категория прайс-листа',
			plural: 'Категории прайс-листа',
			features: 'Характеристики',
			priceCalculation: 'Рассчет стоимости',
			positions: 'Позиции',
			moveTo: 'Переместить в категорию',
			confirmMove: 'Переместить?',
			root: 'Корень',
		},
		pricePosition: {
			title: 'Позиция прайс-листа',
			plural: 'Позиции прайс-листа',
			amount: 'Количество',
			from: 'от',
			to: 'до',
			amountFrom: 'Количество от',
			amountTo: 'Количество до',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			copyPrice: 'Скопировать',
			total: 'Итого',
			name: 'Наименование позиции',
			nameShort: 'Наименование',
		},
		process: {
			title: 'Процесс',
			plural: 'Процессы',
			position: 'Позиция',
			noPositionCategory: 'Не указана категория позиции',
			selectPosition: 'Выбрать позицию заказа',
			workshop: {
				title: 'Цех',
				plural: 'Цеха',
				new: 'Для добавления оборудования и операций цеха необходимо сохранить запись',
			},
			targetWorkshop: 'Цех назначения',
			equipment: 'Оборудование',
			operation: {
				title: 'Операция',
				plural: 'Операции',
			},
			operatorsNumber: 'Количество операторов',
			adjustmentsNumber: 'Количество приладок',
			workingTime: 'Время работы',
		},
		equipment: {
			title: 'Оборудование',
			count: 'Количество оборудования',
		},
		material: {
			group: 'Группа метариалов',
			title: 'Материал',
			workshop: 'Цех',
			position: 'Позиция',
			plural: 'Материалы',
			name: 'Название',
			feature: 'Характеристика',
			format: 'Формат',
			units: 'Еденица измерения',
			copiesNumber: 'Количество на тираж',
			adjustmentsNumber: 'Количество на приладку',
			totalNumber: 'Количество общее',
			count: 'Количество материалов',
			selectPosition: 'Выбрать позицию заказа',
			noPositionCategory: 'Не указана категория позиции',
			type: {
				title: 'Тип материала',
				plural: 'Типы материалов',
				materials: 'Материалы типа',
				new: 'Для добавления материалов к типу необходимо сохранить запись',
			},
		},
		technologyRoadmap: {
			title: 'Технологическая карта',
		},
		order: {
			report: 'Отчет',
			document: 'Документ',
			editReport: 'Редактировать отчет',
			downloadReport: 'Скачать отчет',
			count: 'Кол-во заказов',
			noOrders: 'Заказы не найдены',
			category: 'Категория',
			defaultListError: 'Не выставлено состояние заказа по умолчанию',
			copy: 'Копировать заказ',
			garant: 'Гарантия',
			addGarant: 'Добавить гарантию',
			getprepaid: 'Получен',
			title: 'Заказ',
			plural: 'Заказы',
			number: 'Номер',
			n: '№',
			description: 'Описание',
			comment: 'Комментарий',
			updatedAt: 'Заказ обновлен',
			info: 'Информация о заказе',
			infoShort: 'Информация',
			positions: 'Состав заказа',
			positionsCount: 'Позиций',
			total: 'Общая стоимость',
			totalShort: 'Итого',
			price: 'Стоимость',
			client: 'Контрагент',
			selectClient: 'не выбран',
			invoiceNumber: 'Номер счета',
			invoiceDate: 'Дата счета',
			contractNumber: 'Номер договора',
			contractDate: 'Дата договора',
			paidStatus: 'Статус оплаты',
			paid: 'Оплачено',
			notPaid: 'Не оплачено',
			status: 'Статус заказа',
			manager: 'Менеджер',
			addDescription: 'Добавить описание',
			addComment: 'Добавить комментарий',
			startDate: 'Дата передачи',
			endDate: 'Дата выдачи',
			startDateFrom: 'Дата передачи, с',
			startDateTo: 'Дата передачи, по',
			endDateFrom: 'Дата выдачи, с',
			endDateTo: 'Дата выдачи, по',
			mode: 'Вид',
			priceFrom: 'Стоимость, от',
			priceTo: 'Стоимость, до',
			confirmClientChange: 'В данном заказе есть контактные лица предыдущего контрагента. При смене контрагента они будут удалены.',
			gotoClient: 'Перейти к контрагенту',
			removeContact: 'Убрать из контактных лиц заказа?',
			searchHint: 'Поиск по номеру заказа, контрагенту, менеджеру',
			prepaid: 'Аванс',
			passport: 'Паспорт заказа',
			technologyRoadmap: 'Технологическая карта',
			history: {
				title: 'История заказа',
				created: 'создал(а) заказ',
				invoiceNumber: 'изменил(а) номер счета',
				invoiceDate: 'изменил(а) дату счета',
				contractNumber: 'изменил(а) номер договора',
				contractDate: 'изменил(а) дату договора',
				paid: 'изменил(а) статус оплаты на',
				paidYes: 'Оплачено',
				paidNo: 'Не оплачено',
				status: 'изменил(а) статус заказа на',
				newPosition: 'добавил(а) позицию заказа',
				deletedPosition: 'удалил(а) позицию заказа',
				newWaybill: 'добавил(а) накладную',
				deletedWaybill: 'удалил(а) накладную',
				total: 'итоговая стоимость изменилась: ',
				appointed: 'назначил(а) менеджера: ',
				appointedSelf: 'назначил(а) менеджером себя',
				appointedNull: 'убрал(а) назначение менеджера',
				client: 'изменил(а) контрагента: ',
				startDate: 'изменил(а) дату начала:',
				endDate: 'изменил(а) дату конца:',
				prepaid: 'изменил(а) аванс',
				prepaidCancel: 'убрал(а) аванс',
				newProcess: 'добавил(а) процесс заказа',
				deletedProcess: 'удалил(а) процесс заказа',
				newMaterial: 'добавил(а) материал заказа',
				deletedMaterial: 'удалил(а) материал заказа',
				deletedPositionAttachment: 'удалил(а) из позиции заказа файл(ы)',
				addedPositionAttachment: 'добавил(а) в позицию заказа файл(ы)',
			},
			waybill: {
				tabname: 'Накладная',
				name: 'Наименование',
				gramms: 'Размер листа',
				count: 'Кол. Листов',
				density: 'Плотность',
				gaveOut: 'Выдал',
				defect: 'Брак',
				return: 'Возврат',
				group: 'Группа номенклатуры',
			},
		},
		orderList: {
			title: 'Состояние заказа',
			plural: 'Состояния',
			create: 'Новое состояние',
			name: 'Название состояния',
			default: 'По умолчанию',
			closing: 'Закрывающий',
			roleNames: 'Роли',
			role: 'Роль',
		},
		orderTechPassport: {
			bindingType: 'Тип переплета',
			textColorfulness: 'Красочность текста',
			publicationFormat: 'Формат издания',
			pastingColorfulness: 'Красочность вклейки',
			cutFormat: 'Формат до обреза',
			colorfulFlyleaf: 'Красочность форзаца',
			formatAfterCutting: 'Формат после обреза',
			coverColorfulness: 'Красочность обложки',
			pagesNumber: 'Кол-во страниц (текст + вкл)',
			filmType: 'Тип пленки',
			textVolume: 'Объем текста',
			embossing: 'Тиснение',
			pasteVolume: 'Объем вклейки',
			varnishing: 'Лакирование',
			additionalInformation: 'Дополнительные сведения',
		},
		orderPosition: {
			copy: 'Копировать позицию',
			title: 'Позиция заказа',
			plural: 'Позиции заказа',
			amount: 'Количество',
			price: 'Стоимость',
			priceForOne: 'Цена за шт.',
			category: 'Категория',
			pricePositionName: 'Наименование позиции',
			selectedPricePositionName: 'Выбранная позиция',
			save: 'Сохранить',
			lookingForMatches: 'Поиск совпадений',
			matchingPricePositions: 'Найдено совпадений',
			emptyAmount: 'Показаны совпадения без учёта количества. Чтобы скопировать цену, укажите количество в позиции заказа.',
			noMatches: 'Совпадений нет',
			noCategorySelected: 'Не выбрана категория',
			selectCategoryToEditFeatures: 'Выберите категорию, чтобы редактировать характеристики.',
			noFeaturesSetForCategory: 'Для данной категории не заданы характеристики.',
			gotoCategory: 'Перейти в категорию',
			discountPercent: 'Скидка, %',
			discountDirect: 'Скидка, руб.',
			final: 'Итоговая стоимость',
			members: 'Участники заказа',
			membersShort: 'Участники',
			addMember: 'Добавить участника',
			addParameter: 'Добавить параметр',
			parameters: 'Дополнительные параметры',
			size: 'Размеры',
			unitPrice: 'Цена за ед.',
		},
		orderPositionParameters: {
			plural: 'Параметры в позиции заказа',
		},
		orderPositionRole: {
			title: 'Роль в позиции заказа',
			plural: 'Роли в позиции заказа',
		},
		sorting: {
			title: 'Сортировка',
			newFirst: 'Сначала новые',
			oldFirst: 'Сначала старые',
			priceAsc: 'Сначала дорогие',
			priceDesc: 'Сначала недорогие',
		},
		warehouse: {
			subtitle: 'Склад',
			all: 'Все склады',
			nomenclature: 'Остатки по складам',
			reserves: 'Операции резервирования',
		},
		enterprisedata: {
			sidebar: {
				submenu: '1C-Синхронизация',
				books: '',
				nodes: 'Узлы обмена',
				settings: 'Параметры',
				objects: 'Настройка объектов',
				history: 'История синхронизаций',
				journal: 'Полученные сообщения',
			},
			settings: {
				title: 'Параметры информационной базы',
			},
			importCategory: 'Импортировать категорию в прайс-лист',
		},
		contragents: {
			sidebar: {
				label: 'Контрагенты',
			},
			card: {
				title: 'Карточка контрагента',
				tabs: {
					info: 'Общая информация',
					staff: 'Сотрудники',
					bank: 'Банковские реквизиты',
					projects: 'Проекты',
					docs: 'Документы',
				},
				name: 'Наименование',
				phone: 'Телефон',
				email: 'E-mail',
				legalAddress: 'Юридический адрес',
				actualAddress: 'Фактический адрес',
				comments: 'Комментари',
				basicInfo: 'Основные сведения',
				additionalInfo: 'Дополнительные сведения',
				personName: 'Фамилия, имя, отчество',
				personRole: 'Должность',
				inn: 'ИНН',
				kpp: 'КПП',
				okato: 'ОКАТО',
				oktmo: 'ОКТМО',
				okved: 'ОКВЭД',
				ogrn: 'ОГРН',
				okpo: 'ОКПО',
			},
		},
		nomenclature: {
			title: 'Номенклатура',
			from: 'Откуда',
			to: 'Куда',
			quantity: 'Количество',
			measure: 'Единицы измерения',
			onlyNonZeroQuantity: 'Только ненулевые остатки',
			lastBalanceUpdate: 'Дата обновления',
			noGroups: 'Не удалось получить группы',
		},
		operation: {
			title: 'Операция',
			plural: 'Операции',
			count: 'Количество операций',
			plan: 'Плановые',
			fact: 'Фактические',
			income: 'Доходы',
			costs: 'Расходы',
			transfer: 'Переводы',
			balance: 'Баланс',
			add: 'Добавить операцию',
			all: 'Все',
			edit: 'Изменить операцию',
			noMyCompanies: 'Не найден ни один контрагент с флагом "Моя компания"',
			chartTitle: 'Деньги на счетах',
			notFound: 'Операции не найдены',
			withContragent: 'Операции с контрагентом',
		},
		bankAccount: {
			selectBankAccount: 'Выберите банковский счет',
			allMyBankAccount: 'Все мои банковские счета',
		},
		cashFlow: {
			title: 'Cтатья ДДС',
			plural: 'Статьи ДДС',
			shortTitle: 'Статья',
			allCashFlow: 'Все статьи',
			selectCashFlow: 'Выберите статью',
		},
		contragent: {
			title: 'Контрагент',
			plural: 'Контрагенты',
		},
		currency: {
			title: 'Валюта',
			plural: 'Валюты',
		},
	},
};
