import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Loader } from '@smartplatform/ui';
import WaitTimer from './WaitTimer';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Login extends React.Component {

	@observable login = '';
	@observable password = '';
	@observable captcha = '';
	@observable error = null;
	@observable message = null;
	@observable status = null;
	@observable reloads = 0;
	@observable waitUntil = null;
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.message = null;

		const res = await fetch('/api/checklogin', {
			method: 'POST',
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
		});

		if (res.ok) {
			const status = await res.json();
			console.log('checklogin:', status);
			this.status = status;

			if (status.waitUntil) {
				this.error = t('auth.limitExceeded');
			}
		}
		else {
			this.error = res.statusText;
		}
	};

	onLoginChange = e => {
		this.login = e.target.value;
	};

	onPasswordChange = e => {
		this.password = e.target.value;
	};

	onCaptchaChange = e => {
		this.captcha = e.target.value;
	};

	onSubmit = async () => {
		this.isLoading = true;
		this.error = null;
		this.message = null;
		this.status = null;
		this.login = this.login.trim();
		this.password = this.password.trim();

		if (this.login.length === 0) {
			this.error = t('auth.enterLogin');
			return;
		}
		if (this.password.length === 0) {
			this.error = t('auth.enterPassword');
			return;
		}

		try {
			await store.model.login(this.login, this.password, this.captcha);
		}
		catch (e) {
			this.status = e.loginStatus;
			console.log('?', e.code, e.loginStatus);
			this.error = t(e.code);
			this.captcha = '';
		}
		this.isLoading = false;
	};

	onKeyUp = e => {
		if (e.keyCode === 13) {
			this.onSubmit();
		}
	};

	refresh = () => this.reloads++;

	onWaitTimeout = async () => {
		console.log('onWaitTimeout');
		// await this.init();
		this.error = null;
		const registrationEnabled = this.status ? this.status.registrationEnabled : false;
		this.status = { registrationEnabled };
		this.message = t('auth.tryAgain');
	}

	register = () => store.route.push({ path: '/register' });

	render() {
		const now = new Date().getTime();
		const waitUntil = this.status && this.status.waitUntil ? this.status.waitUntil : null;
		const wait = waitUntil && now < waitUntil;
		const register = this.status && this.status.registrationEnabled;
		const disableForm = this.isLoading || wait;

		return <div className="auth-layout">
			<div className={'auth-form login' + (disableForm ? ' disabled' : '')}>
				<h1>{t('auth.login')}</h1>
				<div className="field">
					<label>{t('user.name')}:</label>
					<input
						type="text"
						name="email"
						value={this.login}
						onChange={this.onLoginChange}
						disabled={disableForm}
					/>
				</div>
				<div className="field">
					<label>{t('user.password')}:</label>
					<input
						type="password"
						name="password"
						value={this.password}
						onKeyUp={this.onKeyUp}
						onChange={this.onPasswordChange}
						disabled={disableForm}
					/>
				</div>
				{(this.status && this.status.captcha) && <>
					<div className="field">
						<label>{t('auth.captcha')}:</label>
						<input
							type="text"
							name="captcha"
							value={this.captcha}
							onKeyUp={this.onKeyUp}
							onChange={this.onCaptchaChange}
						/>
					</div>
					<div className="captcha">
						<img src={`/api/captcha?${this.status.lastTry}-${this.reloads}`} ref={el => this.img = el} alt="" />
						<FontAwesomeIcon icon={faSyncAlt} className="reload" onClick={this.refresh} />
					</div>
				</>}
				<div className="submit">
					<Button variant="primary" onClick={this.onSubmit} disabled={disableForm}>{t('auth.login')}</Button>
					{register && <Button variant="default" className="" onClick={this.register}>{t('auth.registration')}</Button>}
					{this.isLoading && <Loader size={18} style={{ marginLeft: 10 }} />}
				</div>
				{this.error && <div className="error">{this.error}</div>}
				{this.message && <div className="message">{this.message}</div>}
				{wait && <WaitTimer time={this.status.waitUntil} onTimeout={this.onWaitTimeout} />}
			</div>
{/*
			{this.status && <div>
				<pre>now: {JSON.stringify(new Date().getTime())}</pre>
				<pre>{JSON.stringify(
					{
						...this.status,
						lastTry: this.status.lastTry ? new Date(this.status.lastTry).toString() : undefined,
						waitUntil: this.status.waitUntil ? new Date(this.status.waitUntil).toString() : undefined,
					},
					null, 2)}</pre>
			</div>}
*/}
		</div>;
	}

}
