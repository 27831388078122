import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { Avatar, Popover } from '@smartplatform/ui';
import { User } from 'components';
import store from 'client/store';
import './style.scss';
import t from 'i18n';

@observer
export default class MembersFilter extends React.Component {

	@observable users = [];
	@observable selected = null;
	@observable isLoading = true;
	@observable active = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.users = await store.model.User.find({
			order: 'lastName asc, firstName asc, middleName asc',
			fields: ['id', 'username', 'avatar', 'lastName', 'firstName', 'middleName'],
		});
		this.selected = store.model.user;
		this.isLoading = false;
	};

	activate = () => this.active = true;
	deactivate = () => this.active = false;

	select = user => {
		this.selected = user;
		if (this.props.onChange) this.props.onChange(user);
	};

	render() {
		if (this.isLoading) {
			return <div className="members-filter">
				<div className="trigger">
					{t('project.searchByMember')}
				</div>
			</div>;
		}

		return <div className="members-filter" onMouseOver={this.activate} onMouseLeave={this.deactivate}>
			<div className="trigger">
				{this.selected ?
					<User user={this.selected} size={20}/>
					:
					<span className="empty"><FontAwesomeIcon icon={faUser} /> {t('project.searchByMember')}</span>
				}
				<span className="clear" onClick={() => this.select(null)}><FontAwesomeIcon icon={faTimes} /></span>
			</div>
			{this.active && <div className="user-select">
				{this.users.map(user => {
					const className = classNames('user', {
						active: this.selected && this.selected.id === user.id,
					});
					const content = <User size={18} user={user} />
					return <Popover content={content} key={user.id} offset={10} className={className} onClick={() => this.select(user)}>
						<Avatar user={user} size={20} />
					</Popover>
				})}
			</div>}
		</div>;
	}

}
