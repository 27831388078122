import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { Toolbar } from './toolbar';
import AnalyticsStore from './store';
import { Provider } from 'mobx-react';

export class Analytics extends React.Component {
	constructor(props) {
		super(props);
		store.ui.title = t('analytics.plural');
		this.store = new AnalyticsStore();
		this.store.init();
	}
	render() {
		return (
			<Provider store={this.store}>
				<div className='finance-analitics'>
					<Toolbar />
					<div className='charts'></div>
				</div>
			</Provider>
		);
	}
}
