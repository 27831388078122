import { observable } from 'mobx';
import store from 'client/store';

export default class DocumentsListStore {
	@observable search = { name: '', typeName: [], labelNames: [], statusName: [] };
	@observable count = null;
	@observable labels = [];
	@observable types = [];
	@observable statuses = [];
	@observable loadFilter = true;

	constructor() {
		this.init();
	}

	init = async () => {
		this.count = await store.model.ViewDocument.count();

		this.labels = await store.model.Label.find();
		this.labels = this.labels.map((label) => label.name);

		this.types = await store.model.DocumentType.find();
		this.types = this.types.map((type) => type.name);

		this.statuses = await store.model.DocumentStatus.find();
		this.statuses = this.statuses.map((status) => status.name);
		this.loadFilter = false;
	};
}
