import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class ParametersEdit extends React.Component {

	static propTypes = {
		parameter: PropTypes.object.isRequired,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	};

	onValueChange = (e) => this.props.parameter.value = e.target.value;

	onNameChange = (parameter) => this.props.parameter.parameter = parameter;

	save = async () => {
		await this.props.parameter.save();
		this.props.onSave();
	};

	cancel = async () => {
		this.props.onCancel();
	};

	render() {
		const filter = this.props.exclude.length > 0 ?
			{ where: { id: { nin: this.props.exclude } } }
			:
			undefined;

		const parameter = this.props.parameter.parameter
		return <div className="parameter-edit" key={this.props.parameter.parameterId}>
			<RecordSelect
				model={store.model.OrderPositionParameter}
				property="name"
				value={parameter}
				filter={filter}
				onChange={this.onNameChange}
			/>
			<input className='value'
				value={this.props.parameter.value || ''}
				onChange={this.onValueChange}
			/>
			<div className="actions">
				<Button variant="primary" onClick={this.save} disabled={!parameter || !this.props.parameter.value} >{t('ok')}  </Button>
				<Button onClick={this.cancel}>{t('cancel')}</Button>
			</div>
		</div>;
	}

}
