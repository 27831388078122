import { observable, action } from 'mobx';
import store from 'client/store';

export default class ProjectsListStore {

	@observable search = '';
	@observable user = null;
	@observable count = null;

	constructor() {
		this.user = store.model.user;
		this.init();
	}

	init = async () => {
		this.count = await store.model.Project.count();
	};


}
