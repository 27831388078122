import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from 'client/store';

import DocumentsListStore from './documentsListStore';
import DocumentsList from './documents-list/DocumentsList';
import DocumentsToolbar, { DOCUMENTS_MODE_KANBAN } from './documents-toolbar/DocumentsToolbar';
import DocumentsKanban from './documents-kanban/DocumentsKanban';

@observer
export default class Documents extends React.Component {

	@observable search = '';

	constructor(props) {
		super(props);
		this.store = new DocumentsListStore();
	}

	render() {
		let content = <DocumentsList {...this.props} store={this.store} />;

		if (store.local.documentsMode === DOCUMENTS_MODE_KANBAN) {
			content = <DocumentsKanban store={this.store} />;
		}

		return <div className="documents-root">
			<DocumentsToolbar store={this.store} />
			<hr/>
			{content}
		</div>;
	}

}
