import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

import { Button } from '@smartplatform/ui';
import { PROJECTS_MODE_TILES, PROJECTS_MODE_LIST, PROJECTS_MODE_KANBAN } from '../Projects';
import store from 'client/store';
import t from 'i18n';
import ModeTiles from '!!svg-react-loader!client/img/mode-tiles.svg';
import ModeList from '!!svg-react-loader!client/img/mode-list.svg';
import ModeKanban from '!!svg-react-loader!client/img/mode-kanban.svg';
import { MembersFilter } from '../members';
import './style.scss';

@observer
export default class ProjectsToolbar extends React.Component {

	constructor(props) {
		super(props);
		this.store = props.store;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	onSearch = e => {
		this.store.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		if (this.store.reload) this.store.reload();
	};

	onUserChange = user => {
		this.store.user = user;
		this.doSearch();
	};

	switch = (e, mode) => {
		console.log('switch', mode);
		e.preventDefault();
		store.local.projectsMode = mode;
		store.local.save();
		if (mode !== PROJECTS_MODE_LIST) {
			store.route.push({ path: '/projects' });
		}
	};

	create = () => store.route.push({ path: '/projects/create' });

	render() {
		return <div className="projects-toolbar">
			<Button variant="primary" onClick={this.create}>{t('project.create')} <FontAwesomeIcon icon={faPlusCircle} /></Button>
			<div className="project-search">
				<span className="icon"><FontAwesomeIcon icon={faSearch}/></span>
				<input value={this.store.search} onChange={this.onSearch} placeholder={t('project.search')} />
			</div>
			<div className="rest">
				<div className="projects-count">
					{t('project.total')}: {this.store.count}
				</div>
				<MembersFilter onChange={this.onUserChange} />
				<div className="projects-mode">
					<span>{t('project.mode')}:</span>
					<ModeTiles className={store.local.projectsMode === PROJECTS_MODE_TILES ? 'active' : ''} onClick={e => this.switch(e, PROJECTS_MODE_TILES)} />
					<ModeList className={store.local.projectsMode === PROJECTS_MODE_LIST ? 'active' : ''} onClick={e => this.switch(e, PROJECTS_MODE_LIST)} />
					<ModeKanban className={store.local.projectsMode === PROJECTS_MODE_KANBAN ? 'active' : ''} onClick={e => this.switch(e, PROJECTS_MODE_KANBAN)} />
	            </div>
			</div>
		</div>;
	}

}
