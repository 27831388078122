import React from "react";
import store from 'client/store';
import t from 'i18n';
import { ModelList } from 'components';
import { Column, ColorValue } from '@smartplatform/ui';


const List = ({ modelName, path }) => {
    return (
        <ModelList model={store.model[modelName]} path={path} title="Учёт остатков">
            <Column property="number" label="Код счёта" width={80}/>
            <Column property="name" label="Наименование счёта учёта товаров" />
        </ModelList>
    );
}

export default List;