import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import './style.scss';
import PropTypes from 'prop-types';
import { Popover } from '@smartplatform/ui';

@observer
export default class ToolbarSearch extends React.Component {
	static propTypes = {
		content: PropTypes.any.isRequired,
		onSearch: PropTypes.func.isRequired,
		inputValue: PropTypes.any,
		inputPlaceholder: PropTypes.any,
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		inputPlaceholder: t('document.search'),
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { content, className, inputPlaceholder, inputValue, onSearch } = this.props;

		return (
			<Popover
				content={content}
				contentClassName='toolbar-filter-popup'
				position='bottom'
				trigger='click'
				className={`toolbar-search ${className}`}
			>
				<input value={inputValue} onChange={onSearch} placeholder={inputPlaceholder} />
				<span className='icon'>
					<FontAwesomeIcon icon={faSearch} />
				</span>
			</Popover>
		);
	}
}
