import React from 'react';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';

export const renderBankAccount = (bankAccout) =>
	bankAccout && (
		<div className='render-bankAccount'>
			<span className='bank'>{bankAccout.bank}</span>
			<span>{bankAccout.checkingAccount}</span>
		</div>
	);

export const renderContragent = (contragent) =>
	contragent && (
		<div className='render-contragent'>
			<span className='name'>{contragent.name}</span>
			{contragent.myCompany && <FontAwesomeIcon icon={faHome} />}
		</div>
	);

export const renderMoney = (sum, fixed = 0, local = 'ru-RU') => Number(sum.toFixed(fixed)).toLocaleString(local) || 0;
