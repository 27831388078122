import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import PropTypes from 'prop-types';

@observer
export default class ToolbarCreateBtn extends React.Component {
	static propTypes = {
		url: PropTypes.string.isRequired,
	};


	constructor(props) {
		super(props);
	}

	create = () => store.route.push({ path: this.props.url });

	render() {
		return (
			<Button variant='primary' className='toolbar-create' onClick={this.create}>
				<span>{t('add')}</span>
				<FontAwesomeIcon icon={faPlusCircle} />
			</Button>
		);
	}
}
