import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import ExportBtn from '!!svg-react-loader!client/img/document-download.svg';

@observer
export default class ToolbarExportBtn extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <ExportBtn className='toolbar-export' />;
	}
}
