import { observable } from 'mobx';
import store from 'client/store';
import { FIELDS, PAYMENT_INCLUDE, PERIODS, PER_PAGE, TRANSFER } from './constants';
import { endOfMonth, endOfQuarter, endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns';

export default class OperationsStore {
	@observable showCharts = true;
	@observable showEditPopup = false;
	@observable selectedRecord = null;
	@observable isLoading = true;
	// даты
	@observable period = PERIODS[0];
	@observable startDate = null;
	@observable endDate = null;
	// остальные фильтры
	@observable planFactGroup = null;
	@observable project = null;
	@observable paymentType = null;
	@observable cashFlows = [];
	@observable contragent = null;
	@observable bankAccounts = [];
	@observable query = { order: 'date desc' };
	@observable page = 1;
	@observable paymentTypeTotal = null;

	constructor() {}

	init = async () => {
		this.currentDate = new Date();
		this.loadLocalStoreage();
		await this.fetchDashBoardData();
		this.isLoading = false;
	};

	fetchDashBoardData = async () => {
		const { paymentTypeTotal, chartRecords } = await store.model.ViewPayment.getDashboardData({
			startDate: this.startDate,
			endDate: this.endDate,
			bankAccountIds: this.bankAccounts.map((i) => i.id),
			cashFlowIds: this.cashFlows.map((i) => i.id),
			myCompany: this.myCompany?.id,
			paymentType: this.paymentType,
			contragendId: this.contragent?.id,
			projectId: this.project?.id,
		});
		this.chartRecords = chartRecords || [];
		this.paymentTypeTotal = paymentTypeTotal || [];
	};

	// обновление дашборда и таблицы при закрытии попапа редактирования
	refetch = () => {
		this.fetchDashBoardData();
		this.fetchTable?.();
	};

	get queryFilter() {
		const filter = {
			where: { and: [] },
			include: PAYMENT_INCLUDE,
			limit: PER_PAGE,
			skip: PER_PAGE * (this.page - 1),
			order: this.query.order,
		};
		if (this.cashFlows.length) filter.where.and.push({ cashFlowId: { inq: this.cashFlows.map((i) => i.id) } });
		if (this.paymentType) filter.where.and.push({ paymentType: this.paymentType });
		if (this.contragent) filter.where.and.push({ or: [{ payerId: this.contragent.id }, { recipientId: this.contragent.id }] });
		if (this.project) filter.where.and.push({ projectId: this.project.id });
		if (this.bankAccounts.length) {
			const bankAccountIds = this.bankAccounts.map((i) => i.id);
			filter.where.and.push({
				and: [
					{
						and: [
							{ or: [{ payerBankAccountId: { inq: bankAccountIds } }, { recipientBankAccountId: { inq: bankAccountIds } }] },
						],
					},
				],
			});
		}
		if (this.startDate) filter.where.and.push({ date: { gte: this.startDate } });
		if (this.endDate) filter.where.and.push({ date: { lte: this.endDate } });
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.saveToLocalStorage();
		this.fetchDashBoardData();
	};

	onPaymentTypeChange = (type) => {
		this.paymentType = type;
		if (type === TRANSFER) {
			this.contragent = null;
		}

		this.cashFlows = [];
		this.saveToLocalStorage();
		this.fetchDashBoardData();
	};

	toggleProp = (prop) => (value) => {
		this[prop] = typeof value == 'boolean' ? value : !this[prop];
		this.saveToLocalStorage();
	};

	cleanFilters = () => {
		FIELDS.forEach((field) => {
			if (field !== 'showCharts') this[field] = null;
		});
		this.period = PERIODS[0];
		this.cashFlows = [];
		this.bankAccounts = [];
		this.saveToLocalStorage();
		this.fetchDashBoardData();
	};

	onQueryUpdate = (query) => {
		if (query._totalCount === undefined) {
			query._totalCount = this.query._totalCount || 0;
		}
		this.query = query;
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
		this.showEditPopup = true;
	};

	getReloadFunc = (instance) => (this.fetchTable = instance.reload);

	saveToLocalStorage = () => {
		if (!store.local.operations) store.local.operations = {};
		FIELDS.forEach((field) => (store.local.operations[field] = this[field]));
		store.local.save();
	};

	loadLocalStoreage = () => {
		const storageKeys = Object.keys(store.local.operations || {});
		if (storageKeys.length) {
			storageKeys.forEach((key) => {
				const storageValue = store.local.operations[key];
				if (['cashFlows', 'bankAccounts'].includes(key) && !storageValue) {
					this[key] = [];
				} else if (key === 'period' && !storageValue) {
					this[key] = PERIODS[0];
				} else this[key] = storageValue;
			});
		}
	};

	onPeriodChange = (period) => {
		this.period = period;
		switch (period) {
			case 'currentMonth': {
				this.startDate = startOfMonth(this.currentDate);
				this.endDate = endOfMonth(this.currentDate);
				break;
			}
			case 'currentQuarter': {
				this.startDate = startOfQuarter(this.currentDate);
				this.endDate = endOfQuarter(this.currentDate);
				break;
			}
			case 'currentYear': {
				this.startDate = startOfYear(this.currentDate);
				this.endDate = endOfYear(this.currentDate);
				break;
			}
			case 'all': {
				this.startDate = null;
				this.endDate = null;
				break;
			}
		}
		this.saveToLocalStorage();
		this.fetchDashBoardData();
	};
}
