import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFolder,
	faInfoCircle,
	faUniversity,
	faUserFriends
} from "@fortawesome/free-solid-svg-icons";
import { default as translate } from 'i18n';
import Bank from "./bankaccounts";
import Projects from "./projects";
import Staff from './staff';
import Info from "./info";

const t = slug => translate(`contragents.card.tabs.${slug}`);

export default (path, record) => ([
	{
		title: <><FontAwesomeIcon icon={faInfoCircle} />&nbsp;&nbsp;{t('info')}</>,
		path: `${path}/:contragentId`,
		exact: true,
		component: (props) => <Info {...props} contragent={record} path={path} />
	},
	{
		title: <><FontAwesomeIcon icon={faUserFriends} />&nbsp;&nbsp;{t('staff')}</>,
		path: `${path}/:contragentId/staff`,
		component: (props) => <Staff {...props} contragent={record} />,
	},
	{
		title: <><FontAwesomeIcon icon={faUniversity} />&nbsp;&nbsp;{t('bank')}</>,
		path: `${path}/:contragentId/bank-details`,
		component: (props) => <Bank {...props} contragent={record} />,
	},
	{
		title: <><FontAwesomeIcon icon={faFolder} />&nbsp;&nbsp;{t('projects')}</>,
		path: `${path}/:contragentId/projects`,
		component: (props) => <Projects {...props} contragent={record} />
	},
	// {
	// 	title: <><FontAwesomeIcon icon={faFile} />&nbsp;&nbsp;{t('docs')}</>,
	// 	path: `${path}/:contragentId/documents`,
	// 	component: () => <>Документы</>,
	// },
]);