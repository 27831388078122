export const PERIODS = ['all', 'currentMonth', 'currentQuarter', 'currentYear', 'specified'];
export const PLAN = 'PLAN';
export const FACT = 'FACT';
export const ALL = 'ALL';
export const COSTS = 'COSTS';
export const INCOME = 'INCOME';
export const TRANSFER = 'TRANSFER';
export const PAYMENT_INCLUDE = ['cashFlow', 'recipient', 'payer', 'recipientBankAccount', 'currency', 'payerBankAccount', 'project'];
export const PER_PAGE = 10;
export const COLORS = {
	RED: '#FF0000',
	GREEN: '#008000',
	BLUE: '#42AAFF',
};
export const TOTAL_COLORS = {
	INCOME: { color: COLORS.GREEN, prependSymbol: '+ ' },
	COSTS: { color: COLORS.RED, prependSymbol: '- ' },
	TRANSFER: { color: COLORS.BLUE },
	default: { color: COLORS.BLUE },
};
export const FIELDS = [
	'showCharts',
	'period',
	'startDate',
	'endDate',
	'planFactGroup',
	'paymentType',
	'cashFlows',
	'contragent',
	'bankAccounts',
	'project'
];
