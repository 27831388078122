import React from 'react';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import throttle from 'lodash/throttle';

import { Button, Pager, RecordSelect } from '@smartplatform/ui';
import { User, DeleteButton } from 'components';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Members extends React.Component {

	@observable records = [];
	@observable isLoading = true;
	@observable page = 1;
	@observable perPage = 20;

	@observable addNew = false;
	@observable newUser = null;
	@observable newRole = null;

	constructor(props) {
		super(props);
		this.onResize = throttle(this.onResize, 500, { leading: true, trailing: true });
		this.innerHeight = window.innerHeight;
		this.getPerPage();
		this.init();
	}

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	init = async () => {
		this.id = this.props.match.params.projectId;
		this.records = await store.model.ProjectMember.find({
			where: {
				projectId: this.id,
				userId: { neq: null },
			},
			include: ['role', 'user'],
			order: 'id desc',
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
		});
	};

	getPerPage = () => {
		const delta = (window.innerWidth < 768) ? 200 : 240;
		const cellHeight = (window.innerWidth < 992) ? 95 : 55;
		this.perPage = Math.floor((this.innerHeight - delta) / cellHeight)
	};

	onPageChange = page => {
		this.page = page;
		this.init();
	};

	onResize = () => {
		this.getPerPage();
		this.init();
	};

	onRoleChange = async (role, member) => {
		member.role = role;
		await member.save();
	};

	renderUser = (user) => <User user={user} size={22} full />;

	add = () => this.addNew = true;
	cancelAdd = () => this.addNew = false;

	delete = async record => {
		console.log('delete', record);
		await record.delete();
		this.init();
	};

	submitNew = async () => {
		if (this.newUser) {
			const member = new store.model.ProjectMember();
			member.user = this.newUser;
			member.role = this.newRole;
			member.projectId = this.id;
			await member.save();
			await this.init();
			runInAction(() => {
				this.addNew = false;
				this.newUser = null;
				this.newRole = null;
			});
		}
	};

	render() {
		const readOnly = !store.model.ProjectMember || !store.model.ProjectMember.INFO.WRITE;

		return <div className={'project-members fixed-page' + (readOnly ? ' read-only' : '')}>
			<div className="filters">
				{!readOnly && <Button variant="primary" onClick={this.add}>Добавить</Button>}
				<Pager current={this.page} totalCount={this.records.totalCount} itemsPerPage={this.perPage} onChange={this.onPageChange} />
			</div>
			<div className="list">
				{this.addNew && <div key="new" className="member">
					<div className="member-name new-user">
						<RecordSelect
							model={store.model.User}
							computed={this.renderUser}
							value={this.newUser}
							onChange={user => this.newUser = user}
							className="user-select"
						/>
					</div>
					<div className="role">
						<RecordSelect
							model={store.model.ProjectRole}
							property="name"
							value={this.newRole}
							onChange={role => this.newRole = role}
							className="new-user-role-select"
						/>
						<Button onClick={this.submitNew} variant="primary" disabled={!this.newUser}>Добавить</Button>
						<Button onClick={this.cancelAdd}>Отмена</Button>
					</div>
				</div>}
				{this.records.map(member => <div key={member.id} className="member">
					<div className="member-name"><User user={member.user} size={22} full /></div>
					<div className="role">
						{readOnly ?
							member.role ? member.role.name : '-'
							:
							<RecordSelect
								model={store.model.ProjectRole}
								property="name"
								value={member.role}
								onChange={role => this.onRoleChange(role, member)}
								className="role-select"
							/>
						}
						<DeleteButton onConfirm={() => this.delete(member)} />
					</div>
				</div>)}
			</div>
		</div>;
	}

}
