import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Mode from './Mode';
import Toolbar from './toolbar/Toolbar';
import './style.scss';
import store from 'client/store';
import { checkModelAccess } from 'client/tools/checkModelAccess';

@observer
export default class Overview extends React.Component {

	constructor(props) {
		super(props)
		this.error = checkModelAccess('ViewOrder')
	}

	render() {
		if (this.error) return <div className="error">{this.error}</div>;
		return <div className="orders-overview">
			<Toolbar mode={this.props.mode}/>
			<Mode mode={this.props.mode}/>
		</div>;
	}

}
