import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

import { Loader, Checkbox } from '@smartplatform/ui';
import LabelsFilter from './LabelsFilter';
import { formatDate } from 'client/tools';
import pStore from '../store';
import t from 'i18n';
import './style.scss';

@observer
export default class Toolbar extends React.Component {
	
	@observable search = '';
	@observable showErrors = false;

	constructor(props) {
		super(props);
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}
	
	onSearch = async (e) => {
		pStore.search = e.target.value;
		this.doSearch();
	};
	
	doSearch = async () => {
		const id = parseInt(this.props.match.params.projectId);
		if (id) await pStore.load(id);
		if (pStore.kanban) {
			pStore.kanban.reload();
		}
	};

	formatError = str => str
		.replace(/(#\d+)/, '<em>$1</em>')
		.replace(/("[^"]+")/, '<em>$1</em>');
	
	onMouseDown = e => {
		if (this.errorsList && !this.errorsList.contains(e.target)) {
			this.showErrors = false;
		}
	};
	
	onErrorsMount = el => this.errorsList = el;
	
	toggleErrors = () => this.showErrors = !this.showErrors;
	
	clearErrors = e => {
		e.stopPropagation();
		pStore.errors = [];
	}
	
	render() {
		if (pStore.isLoading && !pStore.project) return <div className="project-toolbar">
			<Loader size={20} style={{ marginRight: 10 }} /> Загрузка проекта...
		</div>;
		
		return <div className="project-toolbar">
			<div className="search">
				<input value={pStore.search} onChange={this.onSearch} placeholder={t('kanban.searchHint')} />
			</div>
			{pStore.errors.length > 0 && <div className="errors" ref={this.onErrorsMount}>
				<div className="toggle" onClick={this.toggleErrors}>
					<FontAwesomeIcon icon={faExclamationTriangle}/> Ошибки: {pStore.errors.length}
					<FontAwesomeIcon icon={faTimes} className="clear" onClick={this.clearErrors} />
				</div>
				{this.showErrors && <div className="errors-list">
					{pStore.errors.map((e, i) => <div key={i} className="error-item">
						<span className="date">{formatDate(e.date, 'dd.MM.yyyy HH:mm')}</span>
						<span dangerouslySetInnerHTML={{ __html: this.formatError(e.error.message) }}/>
					</div>)}
				</div>}
			</div>}
			<LabelsFilter />
			{pStore.saving && <Loader size={20} style={{ marginLeft: 10 }} />}
		</div>;
	}
	
}
