import React from 'react';
import { observer } from 'mobx-react';

import { Button, Loader } from '@smartplatform/ui';
import _ from 'lodash';

const FILTER_LABELS = {
	statuses: { name: 'Статус', field: 'statusName' },
	types: { name: 'Тип', field: 'typeName' },
	labels: { name: 'Метки', field: 'labelNames' },
};

@observer
export default class FilterPopup extends React.Component {
	constructor(props) {
		super(props);
	}

	setCheckbox = (field, value) => {
		const { store } = this.props;
		//если значение есть в массиве - удалить, если нет - добавить
		store.search[field] = _.xor(store.search[field], [value]);
	};

	applyFilter = () => {
		const { store } = this.props;
		if (store.reload) store.reload();
	};

	resetFilter = () => {
		const { store } = this.props;
		const labels = ['statusName', 'typeName', 'labelNames'];
		for (const label of labels) {
			store.search[label] = [];
		}
	};

	render() {
		const { store } = this.props;
		const { search, loadFilter } = store;

		let content;
		if (loadFilter) {
			content = <Loader />;
		} else {
			content = (
				<>
					<div className='filters'>
						{Object.keys(FILTER_LABELS).map((filter, index) => (
							<div className='filter-header' key={index}>
								<span className='filter-status-label'>{FILTER_LABELS[filter].name}</span>
								{store[filter].map((item, i) => (
									<div className='filter-item' key={i}>
										<input
											type='checkbox'
											checked={search[FILTER_LABELS[filter].field].includes(item)}
											onChange={() => this.setCheckbox(FILTER_LABELS[filter].field, item)}
										/>
										<span>{item}</span>
									</div>
								))}
							</div>
						))}
					</div>
					<hr />
					<div className='filter-buttons'>
						<Button onClick={this.applyFilter} className='filter-apply'>
							Применить
						</Button>
						<Button onClick={this.resetFilter} className='filter-reset'>
							Сбросить
						</Button>
					</div>
				</>
			);
		}

		return content;
	}
}
