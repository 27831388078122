import { observable, action } from 'mobx';
import store from 'client/store';
import t from 'i18n';

export const INITIAL_LIMIT = 20;

export const TASK_FIELDS = [
	'id',
	'name',
	'priority',
	'boardListId',
	'userId',
	'closed',
	'closedAt',
	'updatedAt',
	'commentsCount',
	'hasDescription',
	'fio',
	'username',
	'labelNames',
	'dueDate',
];

export const USER_FIELDS = [
	'id',
	'avatar',
	'lastName',
	'firstName',
	'middleName',
	'username'
];

export const TASK_INCLUDE = [
	{ relation: 'user', scope: { fields: USER_FIELDS }},
	{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] }},
];

class ProjectStore {

	@observable isLoading = true;
	@observable project = null;
	@observable error = null;
	@observable lists = [];
	@observable search = '';
	@observable user = null;
	@observable labels = [];
	@observable errors = [];
	@observable saving = false;
	@observable kanban = {};

	@action init = async id => {
		this.project = null;
		this.search = '';
		await this.load(id);
	};

	@action load = async id => {
		this.isLoading = true;
		this.error = null;
		this.errors = [];
		this.id = id;

		if (id) {
			try {
				// console.time('project');
				const taskWhere = this.compileTaskFilter();

				const filter = {
					include: [
						{
							relation: 'lists',
							scope: {
								order: 'priority desc',
								fields: ['id', 'name', 'priority', 'color', 'closing' ],
								include: [
									{
										relation: 'tasks',
										scope: {
											where: taskWhere,
											limit: INITIAL_LIMIT,
											fields: TASK_FIELDS,
											order: 'priority desc',
											include: TASK_INCLUDE,
										}
									}
								],
							}
						},
						{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] }},
					],
				};

				// const project = await store.model.Project.getProject({
				// 	id,
				// 	filter,
				// 	taskWhere,
				// });

				const project = await store.model.ViewProject.findById(id, filter);
				await this.getCounts(project, taskWhere);

				this.project = project;
				// console.timeEnd('project');

			}
			catch (e) {
				this.error = e.statusCode === 404 ? new Error(t('project.notFound')) : e;
			}
		}
		else {
			this.error = new Error('Некорректный id');
		}

		this.isLoading = false;
	};

	getCounts = async (project, taskWhere) => {
		for (let list of project.lists()) {
			list.totalCount = await store.model.ViewTask.count({
				and: [
					...taskWhere.and,
					{ boardListId: list.id },
				],
			});
			// console.log('count', list.totalCount);
		}
	};

	compileTaskFilter = () => {
		const taskWhere = {
			and: [
				{ projectId: this.id },
			],
		};

		const trimmed = this.search.trim();
		if (trimmed.length > 0) {
			const words = trimmed.split(' ');
			words.forEach(word => taskWhere.and.push({
				or: [
					{ id: { like: `${word}%` }},
					{ name: { ilike: `%${word}%` }},
					{ fio: { ilike: `%${word}%` }},
					{ username: { ilike: `%${word}%` }},
					{ labelNames: { ilike: `%${word}%` }},
				]
			}));
			// console.log('>', taskWhere.and);
		}

		if (this.user) {
			taskWhere.and.push({ userId: this.user });
		}

		if (this.labels.length > 0) {
			taskWhere.and.push(...this.labels.map(l => ({ labelIds: { like: `%${l.id}%` } })));
		}

		return taskWhere;
	};

	loadMore = async list => {
		const taskWhere = this.compileTaskFilter();
		console.log('loadMore', list.items.length);

		const moreTasks = await store.model.ViewTask.find({
			where: {
				and: [
					...taskWhere.and,
					{ boardListId: list.id },
				],
			},
			order: 'priority desc',
			limit: INITIAL_LIMIT,
			skip: list.items.length,
			include: TASK_INCLUDE,
		});

		return moreTasks.map(task => ({
			id: task.id,
			listId: task.boardListId,
			path: `/tasks/${task.id}`,
			data: task,
		}));
	};

	onError = error => this.errors.push({
		date: new Date(),
		error,
	});
}

export default new ProjectStore();

