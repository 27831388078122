import React from 'react';
import { observer, inject } from 'mobx-react';
import { NumberValue, Loader, Checkbox } from '@smartplatform/ui';
import './style.scss';
import t from 'i18n';
import format from 'date-fns/format';
import ReportStore from './store';
import { EVEN_ROW_STYLE, HEADER_STYLE, ODD_ROW_STYLE, TABLE_STYLE } from './constants';
import { fioShort } from 'client/tools';
import Toolbar from './Toolbar';
import { RefreshButton } from 'components';

@inject('store') @observer
export default class OrdersReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.reportStore = new ReportStore(this.store)
		this.reportStore.init();
	}


	renderTotal = (record) => record.total ? <NumberValue type="currency" value={record.total} /> : '';
	renderCategories = (positions) => {
		if (positions.length > 0) {
			const categoryNames = [...new Set(positions.map(pos => pos.category?.name))];
			return <div>{categoryNames.map((name, i) => <div key={i}>{name}</div>)}</div>
		}
	};

	renderCell = (text, i, style) => {
		if ((typeof text === undefined) && (typeof text === null)) return;
		style = { ...style };
		if (!style.verticalAlign) style.verticalAlign = 'middle';
		return <td key={i} style={style}> {text} </td>
	};

	bodyFields = (record) => [
		this.reportStore.editMode ? <div className='d-flex'><Checkbox onChange={() => this.reportStore.onToggleRowCheckbox(record.id)} checked={!this.reportStore.selectedIds[record.id]} /> {record.id} </div> : record.id,
		record.list?.name,
		record.client?.name,
		this.renderTotal(record),
		record.paid ? t('order.paid') : '',
		fioShort(record.manager),
		this.renderCategories(record.positions()),
		record.invoiceNumber,
		record.contractNumber,
		record.startDate ? format(new Date(record.startDate), "dd.MM.yyyy") : '',
		record.endDate ? format(new Date(record.endDate), "dd.MM.yyyy") : '',
	];

	render() {
		let { records, isLoading, headerFields, onToggleAllIds, onToggleColumnCheckbox, selectedColumns, editMode, isToggleAllIds } = this.reportStore;


		let filename = format(new Date(), "dd.MM.yyyy");
		if (this.store.startDate0) {
			filename = `период ${format(new Date(this.store.startDate0), "dd.MM.yyyy")} - ${this.store.startDate1 ? format(new Date(this.store.startDate1), "dd.MM.yyyy") : format(new Date(), "dd.MM.yyyy")}`
		}

		const header = headerFields.map((field, i) => {
			if (!field) return null;
			if (i === 0) return editMode ? <div className='d-flex'><Checkbox onChange={onToggleAllIds} checked={isToggleAllIds} /> {field}</div> : field;
			else return editMode ? <div className='column-chechbox'><Checkbox onChange={() => onToggleColumnCheckbox(i)} checked={!selectedColumns[i]} /> <div>{field}</div></div> : field;
		}).filter(r => !!r);

		return <div className="orders-report">
			<Toolbar reportStore={this.reportStore} />
			{isLoading ?
				<Loader size={18} />
				:
				records.length === 0 ?
					<div>{t('order.noOrders')}  <RefreshButton onClick={this.reportStore.init} /></div>
					:
					<table style={TABLE_STYLE} id='report-table' className='report-table' >
						<thead>
							<tr>
								{header.map((cell, i) => this.renderCell(cell, i, HEADER_STYLE))}
							</tr>
						</thead>
						<tbody>
							{records.map((record, rowIndex) => <tr key={rowIndex}>
								{this.bodyFields(record).filter((r, i) => !!headerFields[i]).map((cell, i) => {
									let style = rowIndex % 2 === 0 ? EVEN_ROW_STYLE : ODD_ROW_STYLE
									style = { ...style, textAlign: 'left', };
									return this.renderCell(cell, i, style)
								})}

							</tr>
							)}
						</tbody>
					</table>
			}
		</div>;
	}
}

