import React from 'react';
import { ColorValue, Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path }) => {
	const renderRoles = ({roleNames}) => roleNames && roleNames.map((role,i) => <div key={i}>{role}</div>)
	return <ModelList model={store.model[modelName]} path={path} title={t('orderList.plural')} order="priority desc">
		<Column width={200} property="name" label={t('name')} />
		<Column width={50} property="color" className="text-center" label={t('color')}><ColorValue /></Column>
		<Column property="priority" label={t('priority')} className="text-center"/>
		{/*<Column property="total" label={t('project.count')} className="text-center"/>*/}
		<Column width={120} property="default" label={t('orderList.default')} className="text-center" />
		<Column width={120} property="closing" label={t('orderList.closing')} className="text-center" />
		<Column property="description" label={t('description')}/>
		<Column property="roleNames" computed={renderRoles} label={t('orderList.roleNames')}/>
	</ModelList>;
};
