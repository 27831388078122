import { Column, Pager, Table } from '@smartplatform/ui';
import React from 'react';
import appStore from 'client/store';
import t from 'i18n';
import { PER_PAGE, TOTAL_COLORS } from '../constants';
import { inject, observer } from 'mobx-react';
import './style.scss';
import { renderMoney } from 'client/tools';

@inject('store')
@observer
export class List extends React.Component {
	renderTotal = ({ paymentType, total, currencyName }) => {
		const { color, prependSymbol } = TOTAL_COLORS[paymentType] || TOTAL_COLORS['default'];
		return (
			<span style={{ color }}>
				{total ? prependSymbol : ''}
				{renderMoney(total, 2)}
				{/* {currencyName} */}
			</span>
		);
	};

	renderContragent = (prop) => (record) =>
		(
			<>
				<div>{record[prop + 'Name']}</div>
				<div className='bankAccount'>{record[prop + 'BankAccount']?.checkingAccount}</div>
			</>
		);

	render() {
		const { onChange, query, page, onQueryUpdate, onRowClick, queryFilter, getReloadFunc } = this.props.store;
		return (
			<div className='table'>
				<Pager current={page} totalCount={query._totalCount || 0} onChange={onChange('page')} itemsPerPage={PER_PAGE} />
				<Table
					model={appStore.model.ViewPayment}
					query={queryFilter}
					onQueryUpdate={onQueryUpdate}
					getInstance={getReloadFunc}
					onRowClick={onRowClick}
				>
					<Column property='date' label={t('date')} width={100} />
					<Column property='total' label={t('payment.total')} computed={this.renderTotal} width={100} />
					<Column property='cashFlowName' label={t('cashFlow.shortTitle')} />
					<Column computed={this.renderContragent('payer')} label={t('payment.payer')} />
					<Column computed={this.renderContragent('recipient')} label={t('payment.recipient')} />
				</Table>
				{query._totalCount === 0 && <span className='not-found'>{t('operation.notFound')}</span>}
			</div>
		);
	}
}
