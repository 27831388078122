import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import MenuItem from './MenuItem';

@observer
export default class SubMenu extends React.Component {
	
	render() {
		const { submenu } = this.props;
		
		return <div className="submenu">
			<div className="title">{submenu.label}</div>
			<div className="items">
				{submenu.items.map((item, i) => <MenuItem key={submenu.id + '-' + i} item={item} />)}
			</div>
		</div>;
	}
	
}
