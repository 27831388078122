import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Avatar } from '@smartplatform/ui';
import { fioShort, formatDate, relative } from 'client/tools';
import t from 'i18n';

const manualFields = [
	'name',
	'description',
	// 'priority',
	// 'startDate',
	// 'dueDate',
];

@observer
export default class TaskLog extends React.Component {

	constructor(props) {
		super(props);
	}
	
	showDelta = (e, field, index) => {
		e.preventDefault();
		this.props.onShowPopup(field, index);
	};
	

	render() {
		const { logs, index, task } = this.props;

		const taskLog = logs[index];
		
		const isUpdated = field => taskLog.updated && taskLog.updated.find(_field => _field === field);

		const updatedFields = [];
		manualFields.forEach(field => isUpdated(field) && updatedFields.push(field));
		
		let actions = index === 0 ? [{ title: t('history.created'), subjects: [] }] : [];
		
		if (index > 0) {
			if (updatedFields.length > 0) {
				actions.push({
					title: t('history.updated') + ':',
					subject: updatedFields.map((field, i) => {
						const onClick = e => this.showDelta(e, field, index);
						return <React.Fragment key={i}>
							<a href="#" onClick={onClick}>{t(`task.${field}`).toLowerCase()}</a>
							{updatedFields.length > 0 && i < updatedFields.length - 1 ? ', ' : ''}
						</React.Fragment>;
					}),
				});
			}

			if (isUpdated('userId')) {
				if (taskLog.userId === taskLog.ownerId) {
					actions.push({ title: t('history.appointedSelf'), subject: null });
				}
				else if (taskLog.userId) {
					actions.push({ title: t('history.appointed'), subject: fioShort(taskLog.user) });
				}
				else {
					actions.push({ title: t('history.appointedNull'), subject: null });
				}
			}

			if (taskLog.boardList) {
				actions.push({
					title: t('history.status'),
					subject: <><span className="color" style={{ background: taskLog.boardList.color }} />{taskLog.boardList.name}</>,
				});
			}

			if (taskLog.private !== null) {
				actions.push({
					title: taskLog.private ? t('history.private') : t('history.public'),
					subject: null,
				});
			}

			if (taskLog.priority !== null) {
				actions.push({
					title: t('history.priority'),
					subject: null,
				});
			}

			if (taskLog.startDate) {
				actions.push({
					title: t('history.startDate'),
					subject: formatDate(taskLog.startDate, 'dd.MM.yyyy'),
				});
			}
			if (taskLog.dueDate) {
				actions.push({
					title: t('history.dueDate'),
					subject: formatDate(taskLog.dueDate, 'dd.MM.yyyy'),
				});
			}
		}
		
		return <div className="task-log">
			<span className="who"><Avatar user={taskLog.owner} size={24} /> </span>
			<div className="what">
				<span className="subject">{fioShort(taskLog.owner)}</span>
				{actions.map((action, i) => {
					return <span className="action" key={i}>
							<>{action.title} </>
							<span className="subject">{action.subject}</span>
						{actions.length > 0 && i < actions.length - 1 ? ', ' : ''}
						</span>
				})}
				<span className="date">{relative(taskLog.createdAt)}</span>
			</div>
		</div>;
	}
	
}
