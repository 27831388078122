import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import classNames from 'classnames';

export const IconButton = ({ faIcon, icon, text, model, disabled, className, variant, ...restProps }) => {
	if (model) {
		disabled = disabled || !store.model[model]?.INFO.WRITE;
	}
	const _className = classNames('icon-btn', className);
	return (
		<Button className={_className} variant={variant} disabled={disabled} {...restProps}>
			{faIcon && <FontAwesomeIcon icon={faIcon} />}
			{icon}
			{text}
		</Button>
	);
};

IconButton.defaultProps = {
	disabled: false,
	variant: 'primary',
};

IconButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	faIcon: PropTypes.object,
	icon: PropTypes.object,
	model: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default', 'large', 'sm', 'md', 'lg']), PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
