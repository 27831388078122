import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import ToolbarCreateBtn from './ToolbarCreateBtn';
import ToolbarExportBtn from './ToolbarExportBtn';
import ToolbarCount from './ToolbarCount';
import ToolbarSearch from './ToolbarSearch';

@observer
export default class Toolbar extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { children } = this.props;

		return <div className='toolbar'>{children}</div>;
	}
}

Toolbar.CreateBtn = ToolbarCreateBtn;
Toolbar.ExportBtn = ToolbarExportBtn;
Toolbar.Count = ToolbarCount;
Toolbar.Search = ToolbarSearch;
