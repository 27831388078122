import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@smartplatform/ui';
import { fio, fioShort } from 'client/tools';

const User = props => {
	const { user, size, full } = props;
	return <><Avatar user={user} size={size} /> {full ? fio(user) : fioShort(user)}</>;
};

User.propTypes = {
	user: PropTypes.object,
	size: PropTypes.number,
	full: PropTypes.bool,
};

User.defaultProps = {
	size: 22,
};

export default User;
