import { Field, Form, Popup, RelationSelect, Row } from '@smartplatform/ui';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { renderBankAccount, renderContragent } from 'client/tools';
import { PAYMENT_INCLUDE } from '../constants';
import './style.scss';

@inject('store')
@observer
export class EditPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.store = this.props.store;
		this.init();
	}
	init = async () => {
		const { selectedRecord } = this.store;
		this.isNew = !selectedRecord;
		if (this.isNew) {
			this.record = new store.model.Payment({ date: new Date() });
			let defaultCurrency = await store.model.Currency.find({ where: { name: 'руб.' } });
			defaultCurrency = defaultCurrency.length > 0 ? defaultCurrency[0] : null;
			if (defaultCurrency) {
				this.record.currency = defaultCurrency;
				this.record.currencyId = defaultCurrency?.id;
			}
		} else await this.fetchRecord(selectedRecord.id);
		this.isLoading = false;
	};
	fetchRecord = async (id) => {
		this.record = await store.model.Payment.findById(id, { include: PAYMENT_INCLUDE });
	};
	onClose = () => {
		this.store.selectedRecord = null;
		this.store.toggleProp('showEditPopup')(false);
	};

	onAction = () => {
		this.onClose();
		this.store.refetch();
	};

	onContragentChange = (prop) => (value) => {
		this.record[prop] = value;
		this.record[prop + 'BankAccount'] = null;
	};
	render() {
		if (this.isLoading) return null;
		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;
		const { payer, cashFlow, payerBankAccount, recipient, recipientBankAccount, currency, project } = this.record || {};
		const title = this.isNew ? t('operation.add') : t('operation.edit');

		return (
			<Popup className='operation-edit-popup' onClose={this.onClose}>
				<h2>{title}</h2>
				<Form record={this.record} onSave={this.onAction} onDelete={this.onAction} stay>
					<Row>
						<Field property='date' label={t('date')} />
						<Field property='number' label={t('number')} />
					</Row>
					<Row>
						<Field property='total' label={t('payment.total')} />
						<Field property='name' label={t('currency.title')}>
							<RelationSelect
								relation='currency'
								showValue={showValue(t('payment.selectCurrency'), currency?.name)}
								isRequired
							/>
						</Field>
					</Row>
					<Field property='name' label={t('payment.payer')}>
						<RelationSelect
							relation='payer'
							isRequired
							showValue={showValue(t('payment.selectPayer'), renderContragent(payer))}
							onChange={this.onContragentChange('payer')}
							filter={{ order: 'myCompany asc, name asc' }}
							computed={renderContragent}
						/>
					</Field>
					<Field label={t('payment.payerBankAccount')} disabled={!payer}>
						<RelationSelect
							relation='payerBankAccount'
							isRequired
							showValue={showValue(t('payment.selectPayerBankAccount'), renderBankAccount(payerBankAccount))}
							computed={renderBankAccount}
							className='bankAccount'
							onChange={this.onContragentChange('payerBankAccount')}
							filter={{
								where: {
									contragentId: payer?.id,
									id: recipientBankAccount ? { neq: recipientBankAccount.id } : undefined,
								},
							}}
						/>
					</Field>
					<Field property='name' label={t('payment.recipient')}>
						<RelationSelect
							relation='recipient'
							isRequired
							showValue={showValue(t('payment.selectRecipient'), renderContragent(recipient))}
							onChange={this.onContragentChange('recipient')}
							filter={{ order: 'myCompany asc, name asc' }}
							computed={renderContragent}
						/>
					</Field>
					<Field label={t('payment.recipientBankAccount')} disabled={!recipient}>
						<RelationSelect
							relation='recipientBankAccount'
							isRequired
							showValue={showValue(t('payment.selectRecipientBankAccount'), renderBankAccount(recipientBankAccount))}
							computed={renderBankAccount}
							className='bankAccount'
							onChange={this.onContragentChange('recipientBankAccount')}
							filter={{
								where: {
									contragentId: recipient?.id,
									id: payerBankAccount ? { neq: payerBankAccount.id } : undefined,
								},
							}}
						/>
					</Field>
					<Field relation='cashFlow' property='name' className='mt-3' label={t('cashFlow.shortTitle')}>
						<RelationSelect showValue={showValue(t('cashFlow.selectCashFlow'), cashFlow?.name)} isRequired />
					</Field>
					<Field relation='project' property='name' className='mt-3' label={t('project.title')}>
						<RelationSelect showValue={showValue(t('payment.selectProject'), project?.name)} />
					</Field>

					<Field property='description' label={t('description')}>
						<textarea rows={2} />
					</Field>
				</Form>
			</Popup>
		);
	}
}
