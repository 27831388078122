import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import items from './config';
import store from 'client/store';
import './style.scss';

@observer
export default class Menu extends React.Component {
    componentDidMount() {
        store.subscribe('history', this.onRouteChange);
    }

    componentWillUnmount() {
        store.unsubscribe('history', this.onRouteChange);
    }

    onRouteChange = (e) => {
        // console.log('onRouteChange', e);
        store.ui.menu = false;
    };

    render() {
        const menu = items.map((item, i) => {
			if (item.type === 'submenu') {
				if (item.enabled && !item.enabled(store)) return null;
				let visibleCount = item.items.length;
				item.items.forEach(item => {
					if (item.enabled && !item.enabled(store)) visibleCount--;
				});
				if (visibleCount === 0) return null;
				return <SubMenu key={item.id} submenu={item} />;
			}
			return <MenuItem key={'root' + i} item={item} />;
		});

        return (
            <div className="menu" ref={this.onMount} key={store.route.path}>
                {menu}
            </div>
        );
    }
}
