import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { Button, Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const MIN_PASSWORD_LENGTH = 3;

@observer
export default class Register extends React.Component {

	@observable lastName = '';
	@observable firstName = '';
	@observable middleName = '';
	@observable email = '';
	@observable password = '';
	@observable password2 = '';
	@observable captcha = '';
	@observable error = null;
	@observable reloads = 0;
	@observable enabled = true;
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.time = new Date().getTime();
		this.elements = {};
		this.init();
	}

	init = async () => {
		const res = await fetch('/api/authconfig', {
			method: 'POST',
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json",
			},
		});
		if (res.ok) {
			const status = await res.json();
			console.log('authconfig:', status);
		}
		else {
			this.error = res.statusText;
		}
	};

	onMount = (name) => (el) => this.elements[name] = el;

	focus = (name) => this.elements[name] && this.elements[name].focus();

	onFieldChange = (fieldName) => (e) => this[fieldName] = e.target.value;

	refresh = () => this.reloads++;

	isEmailValid = () => {
		if (this.elements['email']) {
			return this.elements['email'].matches(':valid');
		}
		return false;
	};

	validatePassword = (password) => {
		let valid = true;
		let error;
		if (password.length < MIN_PASSWORD_LENGTH) {
			valid = false;
			error = t('auth.minPasswordLength') + ': ' + MIN_PASSWORD_LENGTH;
		}
		// в будущем сюда можно добавить дополнительные условия для пароля
		return { valid, error };
	};

	submit = async () => {
		this.error = null;

		if (this.email.trim().length === 0) {
			this.error = t('auth.enterEmail');
			this.focus('email');
			return;
		}

		if (!this.isEmailValid()) {
			this.error = t('auth.invalidEmail')
			this.focus('email');
			return;
		}

		if (this.password.length === 0) {
			this.error = t('auth.enterPassword');
			this.focus('password');
			return;
		}

		const validity = this.validatePassword(this.password);
		if (!validity.valid) {
			this.error = validity.error;
			this.focus('password');
			return;
		}

		if (this.password2.length === 0) {
			this.error = t('auth.repeatPassword');
			this.focus('password2');
			return;
		}

		if (this.password !== this.password2) {
			this.error = t('auth.noMatch');
			this.focus('password2');
			return;
		}

		if (this.captcha.length === 0) {
			this.error = t('auth.enterCode');
			this.focus('captcha');
			return;
		}

		this.isLoading = true;
		try {
			const user = await store.model.User.create({
				firstName: this.firstName,
				lastName: this.lastName,
				middleName: this.middleName,
				email: this.email,
				password: this.password,
				captcha: this.captcha,
			});
			await store.model.login(this.email, this.password, this.captcha);
		}
		catch (e) {
			this.error = e.message;
			if (e.code === 'INVALID_CAPTCHA') this.focus('captcha');
			if (e.statusCode === 422) {
				this.error = t('auth.emailExists');
				this.focus('email');
			}
		}
		this.reloads++;
		this.isLoading = false;
	};

	render() {
        return <div className="auth-layout">
	        <div className={'auth-form registration' + (this.isLoading ? ' disabled' : '')}>
		        <h1>{t('auth.registration')}</h1>
		        <div className="field">
			        <label>{t('auth.lastName')}:</label>
			        <input type="text" value={this.lastName} onChange={this.onFieldChange('lastName')} />
		        </div>
		        <div className="field">
			        <label>{t('auth.firstName')}:</label>
			        <input type="text" value={this.firstName} onChange={this.onFieldChange('firstName')} />
		        </div>
		        <div className="field">
			        <label>{t('auth.middleName')}:</label>
			        <input type="text" value={this.middleName} onChange={this.onFieldChange('middleName')} />
		        </div>
		        <div className="field">
			        <label className="required">{t('auth.email')}: </label>
			        <input type="email" value={this.email} onChange={this.onFieldChange('email')} required ref={this.onMount('email')} />
		        </div>
		        <div className="field">
			        <label className="required">{t('auth.password')}:</label>
			        <input type="password" autoComplete="new-password" value={this.password} onChange={this.onFieldChange('password')} ref={this.onMount('password')} />
		        </div>
		        <div className="field">
			        <label className="required">{t('auth.repeat')}:</label>
			        <input type="password" autoComplete="new-password" value={this.password2} onChange={this.onFieldChange('password2')} ref={this.onMount('password2')} />
		        </div>
		        <div className="field">
			        <label className="required">{t('auth.captcha')}:</label>
			        <input type="text" name="captcha" value={this.captcha} onChange={this.onFieldChange('captcha')} ref={this.onMount('captcha')} />
		        </div>
		        <div className="captcha">
			        <img src={`/api/captcha?new-${this.time}-${this.reloads}`} ref={el => this.img = el} alt="" />
			        <FontAwesomeIcon icon={faSyncAlt} className="reload" onClick={this.refresh} />
		        </div>
		        <div className="submit">
			        <Button variant="primary" onClick={this.submit}>{t('auth.register')}</Button>
			        {this.isLoading && <Loader size={18} style={{ marginLeft: 10 }} />}
		        </div>
		        {this.error && <div className="error">{this.error}</div>}
	        </div>
        </div>;
    }
}
