import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import pStore from '../store';
import MemberCard from './MemberCard';
import { formatDate, fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Summary extends React.Component {

	@observable isLoading = true;
	@observable tasksCount = null;
	@observable members = [];

	constructor(props) {
		super(props);
		console.log('>>', pStore.project);
		if (pStore.project) this.init();
	}

	init = async () => {
		this.tasksCount = await store.model.Task.count({ projectId: pStore.project.id });
		this.members = await store.model.ProjectMember.find({
			where: {
				projectId: pStore.project.id,
				userId: { neq: null },
			},
			include: [
				{
					relation: 'user',
					scope: {
						include: [
							{
								relation: 'tasks',
								scope: {
									where: {
										projectId: pStore.project.id,
										closed: false,
									},
									fields: ['id'],
								}
							}
						]
					}
				},
				{
					relation: 'role',
					scope: {
						fields: ['id', 'name'],
					}
				}
			],
		});
		this.isLoading = false;
	};

	exportAll = async () => {
		const tasks = await store.model.ViewTask.find({
			where: {
				projectId: pStore.project.id,
			},
			include: ['user'],
			order: 'updatedAt desc',
		});
		console.log('exportAll', tasks.length);
		if (tasks.length > 0) {
			let csvContent = '#;"Номер";"Название";"Отвественный";"Обновлено"\r\n';
			tasks.forEach((task, i) => {
				const data = [
					i + 1,
					task.id,
					'"' + (task.name ? task.name.replace(/"/mg, '""') : '') + '"',
					task.user ? '"' + fio(task.user) + '"' : '',
					formatDate(task.updatedAt),
				];
				const row = data.join(';');
				csvContent += row + '\r\n';
			});
			let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([csvContent]);
			let blob = new Blob([csv], { type: 'text/csv;charset=windows-1251' });
			saveAs(blob, `${pStore.project.name}.csv`);
		}
	};


	render() {
		if (this.isLoading) return <div className="project-summary"/>;

		const sortedMembers = this.members.sort((a, b) => b.user.tasks().length - a.user.tasks().length);

		return <div className="project-summary">
			<div className="task-count">
				{t('project.tasks')}: <strong>{this.tasksCount}</strong>
			</div>
			<div className="export-all-btn" onClick={this.exportAll}><FontAwesomeIcon icon={faSave} /></div>
			<div className="members">
				{sortedMembers.map(member => <MemberCard key={member.id} member={member} />)}
			</div>
		</div>;
	}

}
