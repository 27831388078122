import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';

import { RecordSelect, Avatar, Button } from '@smartplatform/ui';
import { Editor } from 'components';
import { fioShort, relative } from 'client/tools';
import SideBar from './sidebar/SideBar';
import History from './history/History';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Edit extends React.Component {
	
	@observable isLoading = true;
	@observable task = null;
	@observable error = null;
	@observable editName = false;
	@observable editDescription = false;
	@observable changed = false;
	@observable lightboxImages = [];
	@observable imageIndex = 0;
	@observable showImages = false;

	constructor(props) {
		super(props);
		store.ui.title = t('task.title');
		document.title = t('task.title');
		this.init();
	}

	componentWillUnmount() {
		document.title = t('defaultTitle');
	}

	init = async () => {
		this.isLoading = true;
		this.error = null;

		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			try {
				this.task = await store.model.Task.findById(this.id, {
					include: [
						{
							relation: 'project',
							scope: {
								fields: ['id', 'name'],
								include: [
									{ relation: 'members', scope: { fields: ['id', 'userId'], where: { userId: { neq: null }}}},
									{ relation: 'lists', scope: { fields: ['id', 'name', 'color'] }},
								]
							}
						},
						{ relation: 'boardList', scope: { fields: ['id', 'name', 'color' ] }},
						{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar' ] }},
						{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar' ] }},
						{ relation: 'labels', scope: { fields: ['id', 'name', 'color'] }},
					]
				});
				store.ui.title = <>{t('task.title')} <strong>#{this.id}</strong></>;
				document.title = t('task.title') + ' #' + this.id;
			}
			catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.task = new store.model.Task();
			document.title = t('task.create');
		}
		this.isLoading = false;
	};
	
	processText = html => {
		if (html) {
			// нельзя использовать lookbehind - (?<= ) и (?<! ) - https://caniuse.com/js-regexp-lookbehind
			// временно заменяем готовые гиперссылки (тэги <a>) на "--anchor[N]--" и сохраняем их в массиве
			const anchorMatch = html.match(/<a[^<]+<\/a>/ig);
			const anchors = [];
			if (anchorMatch) {
				anchorMatch.forEach((anchor, i) => {
					anchors.push(anchor);
					html = html.replace(anchor, `--anchor${i}--`);
				});
			}
			// заменяем голые URL на гиперссылки
			const urlMatch = html.match(/((https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
			if (urlMatch) {
				urlMatch.forEach((url) => {
					html = html.replace(url, `<a target="_blank" href="${url}">${url}</a>`);
				});
			}
			// возвращаем сохраненные ранее готовые гиперссылки
			anchors.forEach((anchor, i) => html = html.replace(`--anchor${i}--`, anchor));

			const match = html.match(/<img[^>]+>/ig);
			if (match) {
				match.forEach((imgTag, i) => {
					const srcMatch = imgTag.match(/src="([^"]+)"/i);
					if (srcMatch && srcMatch[1]) {
						const src = srcMatch[1];
						try {
							const re = new RegExp(`(${imgTag})`, 'g');
							html = html.replace(re, `<a class="lightbox" target="_blank" href="${src}">$1</a>`);
						}
						catch (e) {
							console.warn(e);
						}
					}
				});
			}
		}
		return html;
	}
	
	onEditorInit = editor => this.editor = editor;
	
	sidebarInstance = instance => this.sidebar = instance;
	
	switchName = async e => {
		if (e) e.preventDefault();
		this.editName = !this.editName;
		if (!this.editName && this.changed) {
			await this.save(['name']);
		}
	};
	
	switchDescription = async () => {
		if (this.editDescription && this.changed) {
			const images = await this.uploadImages();
			console.log('images', images);

			this.task.description = this.editor.getContent();
			await this.task.patchAttributes({ description: this.task.description });

			for (let imgData of images) {
				console.log('>', imgData);
				const { uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await this.task.attachments.add(id);
			}
		}
		this.editDescription = !this.editDescription;
	};

	onDescriptionMount = el => {
		if (el) {
			const links = el.getElementsByClassName('lightbox');
			const imageLinks = [];
			for (let link of links) {
				if (link.tagName.toLowerCase() === 'a') imageLinks.push(link);
			}
			imageLinks.forEach((link, i) => {
				link.onclick = e => {
					e.preventDefault();
					// console.log('click', link.href, link.title);
					this.onImageClick(imageLinks, i);
				};
			});
		}
	};

	onImageClick = (imageLinks, index) => {
		console.log('onImageClick', imageLinks, index);
		this.lightboxImages = imageLinks;
		this.imageIndex = index;
		this.showImages = true;
	};

	hideImages = () => this.showImages = false;
	
	autoHeight = el => {
		if (el) {
			el.style.height = '5px';
			const height = el.scrollHeight + 2;
			el.style.height = Math.max(height, 48) + 'px';
		}
	};
	
	onNameChange = e => {
		this.task.name = e.target.value;
		this.autoHeight(e.target);
		this.changed = true;
	}
	
	onDescriptionChange = content => {
		this.task.description = content;
		this.changed = true;
	};
	
	onUserChange = async user => {
		this.task.user = user;
		await this.save(['userId']);
	}
	
	save = async fields => {
		console.log('save', fields);
		this.changed = false;
		if (fields) {
			const payload = {};
			fields.forEach(property => payload[property] = this.task[property]);
			console.log('payload', payload);
			await this.task.patchAttributes(payload);
		}
		else {
			await this.task.save();
		}
		this.sidebar && this.sidebar.reload();
	};
	
	renderUser = user => <span title={`id: ${user.id}`}><Avatar user={user} size={18}/> {fioShort(user)}</span>;

	uploadImages = () => new Promise((resolve, reject) => {
		this.editor.uploadImages(success => {
			if (success) {
				resolve(success);
			}
			else {
				reject();
			}
		});
	});

	render() {
		if (this.isLoading) return '...';
		if (this.error) return <div className="error">{this.error}</div>;
		
		const membersIds = this.task.project ? this.task.project.members().map(m => m.userId) : [];

		return <div className="task-edit fixed-page">
			<div className="task-main">
				{/*<div className="id">#{this.task.id}</div>*/}
				<div className="name">
					{this.editName ?
						<textarea rows={1} value={this.task.name} onChange={this.onNameChange} ref={this.autoHeight} autoFocus />
						:
						<div className="name-value" dangerouslySetInnerHTML={{ __html: this.task.name.replace(/[\r\n]/g, '<br />')}} />
					}
					<div className="toggle">
						<a href="#" onClick={this.switchName}>{t(this.editName ? 'ok' : 'edit')}</a>
					</div>
				</div>
				<div className="status">
					<div className="user">
						<RecordSelect
							model={store.model.User}
							value={this.task.user}
							onChange={this.onUserChange}
							computed={this.renderUser}
							showValue={this.task.user ? this.renderUser(this.task.user) : <span className="hint">Ответственный</span>}
							searchFields={['username', 'lastName', 'firstName', 'middleName']}
							filter={{ where: { id: { inq: membersIds }}, order: 'lastName asc, firstName asc, username asc'}}
							width={180}
						/>
					</div>
					<div className="updated">
						{t('task.updatedAt')}: <em>{relative(this.task.updatedAt)}</em>
					</div>
				</div>
				<div className="description">
					{this.editDescription ?
						<Editor
							key={this.task.id || 'new'}
							value={this.task.description}
							onChange={this.onDescriptionChange}
							mediaModel={store.model.Attachment}
							onInit={this.onEditorInit}
							height={window.innerWidth < 768 ? 200 : 600}
						/>
						:
						<div
							className="rich-text"
							dangerouslySetInnerHTML={{ __html: this.processText(this.task.description) }}
							ref={this.onDescriptionMount}
						/>
					}
					<div className="toggle">
						<Button onClick={this.switchDescription} variant="primary" size="small">
							{t(this.editDescription ? 'save' : (this.task.description ? 'edit' : 'task.createDescription'))}
						</Button>
					</div>
				</div>
				{this.task.project && <History task={this.task} instance={this.sidebarInstance} onChange={this.save} />}
			</div>
			<SideBar task={this.task} onChange={this.save} />
			{this.showImages &&
				<Lightbox
					mainSrc={this.lightboxImages[this.imageIndex].href}
					imageTitle={<div className="image-title">{this.lightboxImages[this.imageIndex].title}</div>}
					nextSrc={this.lightboxImages[(this.imageIndex + 1) % this.lightboxImages.length].href}
					prevSrc={this.lightboxImages[(this.imageIndex + this.lightboxImages.length - 1) % this.lightboxImages.length].href}
					onCloseRequest={this.hideImages}
					onMovePrevRequest={() => this.imageIndex =  (this.imageIndex + this.lightboxImages.length - 1) % this.lightboxImages.length}
					onMoveNextRequest={() => this.imageIndex = (this.imageIndex + 1) % this.lightboxImages.length}
				/>
			}
		</div>;
	}
	
}
