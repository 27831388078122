import t from 'i18n';

export const HEADER_FIELDS = [
	t('order.n'),
	t('order.status'),
	t('order.client'),
	t('order.total'),
	t('order.paid'),
	t('order.manager'),
	t('order.category'),
	t('order.invoiceNumber'),
	t('order.contractNumber'),
	t('order.startDate'),
	t('order.endDate')
];
export const REPORT_FIELDS = ['id', 'listId', 'clientId', 'total', 'paid', 'managerId', 'invoiceNumber', 'contractNumber', 'startDate', 'endDate'];
export const ORDER_INCLUDE = [
	{ relation: 'list', scope: { fields: ['id', 'name'] } },
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } }
			],
			order: 'id asc',
		}
	},
];
export const TABLE_STYLE = {
	borderCollapse: 'collapse',
	border: '1px solid #ECF3F9',
	verticalAlign: 'middle',
};
export const HEADER_STYLE = { color: '#ECF3F9', background: '#0af' };
export const ODD_ROW_STYLE = { background: '#F8F8FF' };
export const EVEN_ROW_STYLE = { background: '#fff' };