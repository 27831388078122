import React from 'react';
import Label from '../../components/labels/Label';
import { Avatar } from '@smartplatform/ui';
import { fioShort } from '../../tools';

export const renderLabel = (labels) =>
	labels.map((label) => (
		<React.Fragment key={label.id}>
			<Label label={label} size={10} />
			<span style={{ marginRight: '12px' }}>{label.name}</span>
		</React.Fragment>
	));

export const renderType = (type) => type.name;

export const renderStatus = (status) => (
	<>
		<Label label={status} size={10} />
		<span>{status.name}</span>
	</>
);

export const renderUser = (user) => (
	<span title={`id: ${user.id}`}>
		<Avatar user={user} size={18} /> {fioShort(user)}
	</span>
);

export const renderContragentsEdit = (contragent) => contragent.name;

export const renderContragentsView = (contragents) => contragents.map((contragent) => contragent.name).join(', ');
