import React from 'react';
import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path }) => {
	return (
		<ModelList model={store.model[modelName]} path={path} title={t('orderPositionParameters.plural')}>
			<Column property='name' label={t('name')} />
		</ModelList>
	);
};
