import React from 'react';
import ReactECharts from 'echarts-for-react';
import './style.scss';
import { getChartOptions, getPieOptions } from './options';
import { inject, observer } from 'mobx-react';
import { COLORS, COSTS, INCOME } from '../constants';
import t from 'i18n';

const _Charts = ({ store }) => {
	const getTitle = (title) => t('operation.' + title.toLowerCase());
	const { paymentTypeTotal, paymentType, chartRecords } = store;
	const chartOptions = getChartOptions(chartRecords);
	const incomePie = getPieOptions(paymentTypeTotal[INCOME], { color: COLORS.GREEN, title: getTitle(INCOME) });
	const costsPie = getPieOptions(paymentTypeTotal[COSTS], { color: COLORS.RED, title: getTitle(COSTS) });
	const isPieAvailable = (title) => [null, title].includes(paymentType) && paymentTypeTotal[title];

	return (
		<div className='charts' key={paymentType + Object.keys(paymentTypeTotal).join(',')}>
			{chartRecords.length > 0 && (
				<div className='chart'>
					<ReactECharts option={chartOptions} />
				</div>
			)}

			{isPieAvailable(INCOME) && (
				<div className='pie'>
					<ReactECharts option={incomePie} />
				</div>
			)}
			{isPieAvailable(COSTS) && (
				<div className='pie'>
					<ReactECharts option={costsPie} />
				</div>
			)}
		</div>
	);
};
export const Charts = inject('store')(observer(_Charts));
