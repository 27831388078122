import t from 'i18n';
import {
	faBox,
	faCheck,
	faUserCog,
	faUserLock,
	faCog,
	faCalendarCheck,
	faCogs,
	faRandom,
	faSitemap,
	faBook,
	faFileExcel,
	faCubes,
	faStream,
	faBoxes,
	faExclamation,
	faStamp,
} from '@fortawesome/free-solid-svg-icons';
import AnalyticsIcon from '!!svg-react-loader!client/img/sidebar/analytics.svg';
import OperationsIcon from '!!svg-react-loader!client/img/sidebar/operation.svg';
import ProjectIcon from '!!svg-react-loader!client/img/sidebar/project.svg';
import TaskIcon from '!!svg-react-loader!client/img/sidebar/task.svg';
import DocumentIcon from '!!svg-react-loader!client/img/sidebar/document.svg';
import ContragentIcon from '!!svg-react-loader!client/img/sidebar/contragent.svg';
import UserIcon from '!!svg-react-loader!client/img/sidebar/user.svg';
import RoleIcon from '!!svg-react-loader!client/img/sidebar/role.svg';
import AclIcon from '!!svg-react-loader!client/img/sidebar/acl.svg';
import OrderIcon from '!!svg-react-loader!client/img/sidebar/order.svg';
import CategoryIcon from '!!svg-react-loader!client/img/sidebar/category.svg';
import CharacteristicIcon from '!!svg-react-loader!client/img/sidebar/characteristic.svg';
import LabelIcon from '!!svg-react-loader!client/img/sidebar/label.svg';
import PriceIcon from '!!svg-react-loader!client/img/sidebar/price.svg';
import TaskStatus from '!!svg-react-loader!client/img/sidebar/task-status.svg';
import ProjectStatusIcon from '!!svg-react-loader!client/img/sidebar/project-status.svg';
import ProjectRoleIcon from '!!svg-react-loader!client/img/sidebar/project-role.svg';
import RoleMappingIcon from '!!svg-react-loader!client/img/sidebar/role-mapping.svg';
import React from 'react';

export default [
	{
		type: 'submenu',
		id: 'management',
		label: t('management'),
		items: [
			{
				type: 'item',
				label: t('project.plural'),
				path: '/projects',
				reactIcon: <ProjectIcon />,
				enabled: (store) => store.model.Project && store.model.Project.INFO.READ,
			},
			{
				type: 'item',
				label: t('task.plural'),
				path: '/tasks',
				reactIcon: <TaskIcon />,
				enabled: (store) => store.model.Task && store.model.Task.INFO.READ,
			},
			{
				type: 'item',
				path: '/contragents',
				label: 'Контрагенты',
				reactIcon: <ContragentIcon />,
				enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
			},
			{
				type: 'item',
				path: '/documents',
				label: t('documents'),
				reactIcon: <DocumentIcon />,
				// enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
			},
		],
	},
	{
		type: 'submenu',
		id: 'warehouse',
		label: 'Склад',
		enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,
		items: [
			{
				type: 'item',
				label: 'Учёт остатков',
				path: '/nomenclature-balance-accounts',
				icon: faBoxes,
				enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,
			},
			{
				type: 'item',
				label: t('warehouse.nomenclature'),
				path: '/nomenclature',
				icon: faStream,
				enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,
			},
			{
				type: 'item',
				label: t('warehouse.reserves'),
				path: '/nomenclature-reserves',
				icon: faBox,
				enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,
			},
		],
	},
	{
		type: 'submenu',
		id: 'finance',
		label: t('finance'),
		enabled: (store) => store.model.ACL && store.model.ACL.INFO.WRITE,
		items: [
			// {
			// type: 'item',
			// label: t('budget.plural'),
			// path: '/budgets',
			// icon: faCalculator,
			// },
			// {
			// type: 'item',
			// label: t('budgetRole.plural'),
			// path: '/budgetroles',
			// icon: faUserFriends,
			// },
			// {
			// type: 'item',
			// path: '/payments',
			// label: t('payment.plural'),
			// icon: faReceipt,
			// },
			// {
			// type: 'item',
			// path: '/contracts',
			// label: t('contract.plural'),
			// icon: faReceipt,
			// },
			{
				type: 'item',
				path: '/analytics',
				label: t('analytics.plural'),
				reactIcon: <AnalyticsIcon />,
			},
			{
				type: 'item',
				path: '/operations',
				label: t('operation.plural'),
				reactIcon: <OperationsIcon />,
			},
		],
	},
	{
		type: 'submenu',
		id: 'sales',
		label: t('sales.title'),
		items: [
			{
				type: 'item',
				label: t('order.plural'),
				path: '/orders',
				reactIcon: <OrderIcon />,
				enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
			},
			{
				type: 'item',
				label: t('order.report'),
				path: '/ordersreport',
				icon: faFileExcel,
				enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
			},
			{
				type: 'item',
				label: t('priceList.title'),
				path: '/pricecategories',
				reactIcon: <PriceIcon />,
				enabled: (store) => store.model.PriceCategory && store.model.PriceCategory.INFO.READ,
			},
			{
				type: 'item',
				label: t('feature.plural'),
				path: '/features',
				reactIcon: <CharacteristicIcon />,
				enabled: (store) => store.model.Feature && store.model.Feature.INFO.READ,
			},
			{
				type: 'item',
				label: t('orderList.plural'),
				path: '/orderlists',
				icon: faCalendarCheck,
				enabled: (store) => store.model.OrderList && store.model.OrderList.INFO.READ,
			},
			{
				type: 'item',
				label: t('orderPositionRole.plural'),
				path: '/orderpositionroles',
				icon: faUserCog,
				enabled: (store) => store.model.OrderPositionRole && store.model.OrderPositionRole.INFO.READ,
			},
			{
				type: 'item',
				label: t('orderPositionParameters.plural'),
				path: '/orderpositionparameters',
				icon: faCogs,
				enabled: (store) => store.model.OrderPositionParameter && store.model.OrderPositionParameter.INFO.READ,
			},
			{
				type: 'item',
				label: t('technologyRoadmap.title'),
				path: '/technicalroadmap',
				icon: faRandom,
				enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
			},
		],
	},
	{
		type: 'submenu',
		id: 'settings',
		label: t('settings'),
		enabled: (store) => store.model.ACL && store.model.ACL.INFO.WRITE,
		items: [
			{
				type: 'item',
				label: t('label.plural'),
				path: '/labels',
				reactIcon: <LabelIcon />,
			},
			{
				type: 'item',
				label: t('documentType.plural'),
				path: '/document-types',
				icon: faStamp,
			},
			{
				type: 'item',
				label: t('documentStatus.plural'),
				path: '/document-status',
				icon: faExclamation,
			},
			{
				type: 'item',
				label: t('projectRole.plural'),
				path: '/projectroles',
				reactIcon: <ProjectRoleIcon />,
			},
			{
				type: 'item',
				path: '/boardlists',
				label: t('boardList.plural'),
				reactIcon: <TaskStatus />,
				icon: faCheck,
			},
			{
				type: 'item',
				path: '/projectlists',
				label: t('projectList.plural'),
				reactIcon: <ProjectStatusIcon />,
			},
			{
				type: 'item',
				path: '/contragent-types',
				label: t('contragentTypes'),
				icon: faUserLock,
				enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
			},
		],
	},
	{
		type: 'submenu',
		id: '1c',
		label: t('enterprisedata.sidebar.submenu'),
		items: [
			{
				type: 'item',
				label: t('enterprisedata.sidebar.settings'),
				path: '/1c-options',
				icon: faCog,
				enabled: (store) => store.model.EdOption && store.model.EdOption.INFO.READ,
			},
			{
				type: 'item',
				label: t('enterprisedata.sidebar.nodes'),
				path: '/1c-nodes',
				icon: faSitemap,
				enabled: (store) => store.model.EdNode && store.model.EdNode.INFO.READ,
			},
			{
				type: 'item',
				label: t('enterprisedata.sidebar.objects'),
				path: '/1c-objects',
				icon: faCubes,
				enabled: (store) => store.model.EdObject && store.model.EdObject.INFO.READ,
			},
			{
				type: 'item',
				label: t('enterprisedata.sidebar.journal'),
				path: '/1c-journal',
				icon: faBook,
				enabled: (store) => store.model.EdFile && store.model.EdFile.INFO.READ,
			},
		],
	},
	{
		type: 'submenu',
		id: 'admin',
		label: t('admin'),
		enabled: (store) => store.model.ACL && store.model.ACL.INFO.WRITE,
		items: [
			{
				type: 'item',
				label: t('users'),
				path: '/users',
				reactIcon: <UserIcon />,
			},
			{
				type: 'item',
				label: t('roles'),
				path: '/roles',
				reactIcon: <RoleIcon />,
			},
			{
				type: 'item',
				path: '/acls',
				label: t('acls'),
				reactIcon: <AclIcon />,
			},
			{
				type: 'item',
				path: '/rolemappings',
				label: t('roleMappings'),
				reactIcon: <RoleMappingIcon />,
			},
		],
	},
];
