import React from 'react';
import { RecordSelect, Button, ButtonGroup, Select, DatePicker } from '@smartplatform/ui';
import { COSTS, FACT, INCOME, PERIODS, PLAN, TRANSFER } from '../constants';
import appStore from 'client/store';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { SearchButton } from 'components';
import { renderBankAccount } from 'client/tools/renders';

@inject('store')
@observer
export class Filters extends React.Component {
	render() {
		const { store } = this.props;
		const {
			onChange,
			contragent,
			paymentType,
			bankAccounts,
			cleanFilters,
			cashFlows,
			planFactGroup,
			onPaymentTypeChange,
			period,
			onPeriodChange,
			project,
		} = store;

		const showValue = (placeholder, value) => value || <span className='placeholder'>{placeholder}</span>;

		const showBankAccountValue = bankAccounts.length ? (
			t('payment.selectedBankAccounts') + ': ' + bankAccounts.length
		) : (
			<span className='placeholder'>{t('payment.allBankAccounts')}</span>
		);

		const showCashFlow = cashFlows.length ? (
			t('payment.selectedCashFlows') + ': ' + cashFlows.length
		) : (
			<span className='placeholder'>{t('payment.allCashFlows')}</span>
		);

		const selectProps = { noTotal: true, maxItems: 10, itemsPerPage: 1000, width: 200 };
		return (
			<div className='filters'>
				<div className='top'>
					<Select
						key={period}
						items={PERIODS.map((name, i) => ({ value: name, label: t('datePeriod.' + name) }))}
						value={period}
						onChange={onPeriodChange}
						showValue={period ? t('datePeriod.' + period) : t('datePeriod.select')}
						noSearch
						isRequired
					/>
					{period === 'specified' &&
						['startDate', 'endDate'].map((date) => <DatePicker key={date} value={store[date]} onChange={onChange(date)} />)}

					<RecordSelect
						model={appStore.model.ViewBankAccount}
						onChange={onChange('bankAccounts')}
						value={bankAccounts}
						property='checkingAccount'
						showValue={showBankAccountValue}
						computed={renderBankAccount}
						filter={{ where: { myCompany: true } }}
						{...selectProps}
					/>
					<RecordSelect
						model={appStore.model.Contragent}
						showValue={showValue(t('payment.allContragents'), contragent?.name)}
						property='name'
						onChange={onChange('contragent')}
						filter={{ order: 'name asc', where: { or: [{ myCompany: false }, { myCompany: null }] } }}
						value={contragent}
						width={200}
					/>
					<RecordSelect
						model={appStore.model.Project}
						showValue={showValue(t('payment.allProjects'), project?.name)}
						property='name'
						onChange={onChange('project')}
						value={project}
						width={200}
					/>
					<SearchButton disabled />
					<Button onClick={cleanFilters} className='transparent'>
						{t('clean')}
					</Button>
				</div>
				<div className='bottom'>
					<ButtonGroup>
						{[PLAN, FACT, null].map((name, index) => (
							<Button
								variant={planFactGroup === name ? 'primary' : 'default'}
								key={index}
								onClick={() => (store.planFactGroup = name)}
								disabled
							>
								{t('operation.' + (name?.toLocaleLowerCase() || 'all'))}
							</Button>
						))}
					</ButtonGroup>
					<ButtonGroup>
						{[INCOME, COSTS, TRANSFER, null].map((name, index) => (
							<Button
								variant={paymentType === name ? 'primary' : 'default'}
								key={index}
								onClick={() => onPaymentTypeChange(name)}
							>
								{t('operation.' + (name?.toLocaleLowerCase() || 'all'))}
							</Button>
						))}
					</ButtonGroup>
					{paymentType !== TRANSFER && (
						<RecordSelect
							{...selectProps}
							model={appStore.model.CashFlow}
							property='name'
							onChange={onChange('cashFlows')}
							filter={{ order: 'name asc' }}
							value={cashFlows}
							showValue={showCashFlow}
						/>
					)}
				</div>
			</div>
		);
	}
}
