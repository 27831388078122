import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';

export const MaterialTypes = ({path,modelName}) => <Switch>
	<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} />}/>
	<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} />}/>
	<Route path={`${path}/:id`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} />}/>
</Switch>;
