import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import { Editor } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class PostComment extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		onSubmit: PropTypes.func,
	};
	
	static defaultProps = {
		relation: 'comments',
	};
	
	@observable value = '';
	@observable isSubmitting = false;
	@observable error = null;
	
	constructor(props) {
		super(props);
	}

	onEditorInit = editor => {
		this.editor = editor;
	};
	
	onChange = value => this.value = value;
	
	submit = async () => {
		this.error = null;
		if (this.value.trim().length === 0) {
			this.error = t('comment.empty');
			return;
		}
		try {
			this.isSubmitting = true;

			const images = await this.uploadImages();

			const comment = new store.model.Comment();
			comment.text = this.editor.getContent();
			await comment.save();

			for (let imgData of images) {
				console.log('>', imgData);
				const { element, uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await comment.attachments.add(id);
			}

			await this.props.record[this.props.relation].add(comment.id);
			this.props.onSubmit && this.props.onSubmit(comment);
			this.value = '';
			this.isSubmitting = false;
		}
		catch(e) {
			this.error = e.message;
		}
	};

	uploadImages = () => new Promise((resolve, reject) => {
		this.editor.uploadImages(success => {
			if (success) {
				resolve(success);
			}
			else {
				reject();
			}
		});
	});
	
	render() {
		console.log('>', t('comment'), t);
		return <div className="post-comment">
			<div className="post-title">{t('comment.postTitle')}</div>
			<Editor
				value={this.value}
				onChange={this.onChange}
				mediaModel={store.model.Attachment}
				height={250}
				onInit={this.onEditorInit}
				menubar={false}
				toolbar="undo redo | bold italic | alignleft aligncenter alignjustify alignright | numlist bullist | blockquote | code | link image media"
				disabled={this.isSubmitting}
			/>
			<div className="actions">
				<Button onClick={this.submit} className="btn-md btn-primary" disabled={this.isSubmitting || this.value.trim().length === 0}>{t('comment.post')}</Button>
			</div>
			{this.error && <div className="error">{this.error}</div>}
		</div>;
	}
	
}
