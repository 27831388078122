import React from 'react';
import { observer } from 'mobx-react';

import { Column } from '@smartplatform/ui';

import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { ModelList } from '../../../components';
import { renderContragentsView, renderLabel, renderStatus, renderType } from '../constants';
import Clip from '!!svg-react-loader!client/img/clip.svg';
import ViewDocument from '!!svg-react-loader!client/img/view-document.svg';
import { observable } from 'mobx';

@observer
export default class List extends React.Component {
	@observable filters = {};

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.doSearch;
		store.local.itemsPerPage = 10000;
		this.init();
	}

	init = async () => {
		const documentsWhere = this.compileFilter();

		this.filters = {
			include: ['type', 'status', 'contragent', 'labels', 'attachments'],
			where: documentsWhere,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			order: this.order,
		};
	};

	compileFilter = () => {
		const { search } = this.store;
		const searchFields = ['typeName','statusName', 'labelNames']

		const trimmed = search.name.trim();
		const where = {
			and: [{ name: { like: `%${trimmed}%` } }],
		};

		for (const field of searchFields) {
			if (search[field].length) {
				let RExp = search[field].join('|');
				RExp = new RegExp(RExp, 'gi');
				where.and.push({ [field]: { regexp: `${RExp}` } });
			}
		}

		return where;
	};

	doSearch = () => {
		this.page = 1;
		this.init();
	};

	onOpenDocuments = (e, files) => {
		e.stopPropagation();
		for (let file of files) {
			window.open(`${window.location.origin}/api/containers/attachments/download/${file.id}-filename`);
		}
	};

	renderFileView = (files) =>
		files.length ? (
			<div className='field-file'>
				<ViewDocument className='view-document' onClick={(e) => this.onOpenDocuments(e, files)} />
				<Clip />
				<span>{files.map(({ filename }) => filename).join(', ')}</span>
			</div>
		) : (
			'-'
		);

	render() {
		const { modelName } = this.props;

		return (
			<div className='documents-list'>
				<ModelList model={store.model[modelName]} title={t('document.plural')} filter={this.filters} disableFilters externalSearch>
					<Column property='name' label={t('name')} />
					<Column property='number' label={t('number')} />
					<Column relation='type' computed={renderType} label={t('type')} />
					<Column relation='contragent' computed={renderContragentsView} label={t('client.plural')} />
					<Column relation='labels' computed={renderLabel} label={t('label.plural')} />
					<Column relation='status' computed={renderStatus} label={t('status')} />
					<Column relation='attachments' computed={this.renderFileView} label={t('files')} />
				</ModelList>
			</div>
		);
	}
}
