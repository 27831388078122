import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { ColorUtils } from '@smartplatform/ui';
import { MembersAvatars } from '../members';
import store from 'client/store';
import { relative } from 'client/tools';
import t from 'i18n';

const stopPropagation = e => e.stopPropagation();

@observer
export default class TaskItem extends React.Component {

	constructor(props) {
		super(props);
	}
	
	onClick = () => store.route.push({ path: `/projects/${this.props.project.id}` });
	
	render() {
		const { project } = this.props;
		
		const className = classNames('project-item', {});

		// const { r, g, b } = ColorUtils.hexToRgb(project.projectList.color);

		const listStyle = {
			background: project.projectList ? project.projectList.color : '#ccc',
			// background: `rgba(${r}, ${g}, ${b}, 0.5)`,
			// background: `linear-gradient(to top, rgba(${r}, ${g}, ${b}, 1) 90%, rgba(${r}, ${g}, ${b}, 0.5) 100%)`,
			color: project.projectList ? ColorUtils.textColor(project.projectList.color) : '#000',
		};
		
		return <div className={className} onClick={this.onClick} style={{ borderLeftColor: project.projectList ? project.projectList.color : '#ccc' }}>
			<div className="left">
				<div className="top">
					<strong className="name">{project.name}</strong>
					{project.client && <span className="client">{t('client.title')}: <em>{project.client.name}</em></span>}
					{/*<em>#{project.id}</em>*/}
				</div>
				<div className="bottom">
					<span className="project-list" style={listStyle}>{project.projectList ? project.projectList.name : '-'}</span>
					<div className="tasks">
						{t('project.tasksCount')}: <em>{project.tasksCount}</em> (<em className="opened">{project.openedCount}</em>/<em className="closed">{project.closedCount}</em>)
					</div>
					<div className="last-updated">
						<span>{t('updatedAt')}:</span> <em>{relative(project.lastUpdate)}</em>
					</div>
				</div>
			</div>
			<div className="right">
				<MembersAvatars project={project} size={24} avatarsCount={4} />
			</div>
		</div>;
	}
	
}
