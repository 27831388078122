import React from 'react';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import store from 'client/store';
import t from 'i18n';
import ModeList from '!!svg-react-loader!client/img/mode-list.svg';
import ModeKanban from '!!svg-react-loader!client/img/mode-kanban.svg';
import './style.scss';

import Toolbar from '../../../components/toolbar';
import FilterPopup from './filter-popup/FilterPopup';

export const DOCUMENTS_MODE_LIST = 'list';
export const DOCUMENTS_MODE_KANBAN = 'kanban';

@observer
export default class DocumentsToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	onSearch = (e) => {
		this.store.search.name = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		if (this.store.reload) this.store.reload();
	};

	switch = (e, mode) => {
		console.log('switch', mode);
		e.preventDefault();
		store.local.documentsMode = mode;
		store.local.save();
		if (mode !== DOCUMENTS_MODE_LIST) {
			store.route.push({ path: '/documents' });
		}
	};

	create = () => store.route.push({ path: '/documents/create' });

	render() {
		const filterContent = <FilterPopup store={this.store} />

		return (
			<div className='documents-toolbar'>
				<Toolbar store={this.store}>
					<Toolbar.CreateBtn url='/documents/create' />
					<Toolbar.Search content={filterContent} inputValue={this.store.search.name} onSearch={this.onSearch}/>
					<Toolbar.Count text={t('document.total')} count={this.store.count} />
					<Toolbar.ExportBtn />
				</Toolbar>
				<div className='right'>
					<div className='documents-mode'>
						<ModeList
							className={store.local.documentsMode === DOCUMENTS_MODE_LIST ? 'active' : ''}
							onClick={(e) => this.switch(e, DOCUMENTS_MODE_LIST)}
						/>
						<ModeKanban
							className={store.local.documentsMode === DOCUMENTS_MODE_KANBAN ? 'active' : ''}
							onClick={(e) => this.switch(e, DOCUMENTS_MODE_KANBAN)}
						/>
					</div>
				</div>
			</div>
		);
	}
}
