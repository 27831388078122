import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

import ProjectsList from './projects-list/ProjectsList';
import ProjectsKanban from './projects-kanban/ProjectsKanban';
import ProjectTiles from './projects-tiles/ProjectTiles';
import store from 'client/store';
import ProjectsListStore from './projectsListStore';
import ProjectsToolbar from './projects-toolbar/ProjectsToolbar';
import t from 'i18n';

export const PROJECTS_MODE_TILES = 'tiles';
export const PROJECTS_MODE_LIST = 'list';
export const PROJECTS_MODE_KANBAN = 'kanban';

@observer
export default class Projects extends React.Component {

	@observable search = '';

	constructor(props) {
		super(props);
		store.ui.title = t('project.plural');
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.store = new ProjectsListStore();
	}

	switch = (e, mode) => {
		e.preventDefault();
		store.local.projectsMode = mode;
		store.local.save();
		if (mode === PROJECTS_MODE_KANBAN) {
			store.route.push({ path: '/projects' });
		}
	};

	onSearch = e => {
		this.store.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		console.log('doSearch', this.store.reload);
		if (this.store.reload) this.store.reload();
	};

	create = () => store.route.push({ path: '/projects/create' });

	render() {
		let content = <ProjectsList {...this.props} store={this.store} />;

		if (store.local.projectsMode === PROJECTS_MODE_KANBAN) {
			content = <ProjectsKanban store={this.store} />;
		}
		else if (store.local.projectsMode === PROJECTS_MODE_TILES) {
			content = <ProjectTiles store={this.store} />;
		}

		return <div className="projects-root">
			<ProjectsToolbar store={this.store} />
			{content}
		</div>;
	}

}
