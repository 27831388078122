import React from 'react';

import { Button, Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';
import FormContext from '@smartplatform/ui/src/components/form/context';
import { observable } from 'mobx';

export default class extends React.Component {
	static contextType = FormContext;
	@observable hiddenFields = [];
	constructor(props) {
		super(props);
	}
	onCreate = () => {
		store.route.push({
			path: `${this.props?.match?.url}/create`,
		});
	};
	render() {
		return (
			<div>
				<ModelList
					model={store.model[this.props.modelName]}
					title='Банковские реквезиты'
					filter={{
						where: { contragentId: this.props.contragentId },
						include: ['currency'],
					}}
				>
					<Column property='bank' label='Банк' />
					<Column property='bic' label='БИК' />
					<Column property='checkingAccount' label='Рассчётный счёт' />
					<Column relation='currency' property='name' label={t('currency.title')} />
				</ModelList>
			</div>
		);
	}
}
