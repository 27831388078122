import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import throttle from 'lodash/throttle';

import { RecordSelect, Select, Pager } from '@smartplatform/ui';
import TaskItem from './TaskItem';
import { User } from 'components';
import store from 'client/store';
import './style.scss';
import t from 'i18n';

const ALL = 'ALL';
const NONE = 'NONE';

@observer
export default class List extends React.Component {

	@observable records = [];
	@observable isLoading = true;
	@observable page = 1;
	@observable perPage = 20;
	@observable closed = false;
	@observable user = null;
	@observable boardList = ALL;
	@observable order = 'updatedAt desc';

	constructor(props) {
		super(props);
		store.ui.title = 'Задачи';
		this.onResize = throttle(this.onResize, 500, { leading: true, trailing: true });
		this.user = store.model.user;
		this.innerHeight = window.innerHeight;
		this.getPerPage();
		this.init();
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.onResize);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}
	
	init = async () => {
		const where = { and: [] };

		if (this.user && this.user !== ALL) {
			where.and.push({ userId: this.user === NONE ? null : this.user.id });
		}

		if (this.closed !== null) {
			if (this.closed) {
				where.and.push({ closed: true });
			}
			else {
				where.and.push({
					or: [
						{closed: false },
						{closed: null },
					]
				});
			}
		}
		if (this.boardList !== null) where.and.push({ boardListId: this.boardList.id });

		this.records = await store.model.Task.find({
			where,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			order: this.order,
			include: [
				{ relation: 'project', scope: { fields: ['id', 'name'] }},
				{ relation: 'boardList', scope: { fields: ['id', 'name', 'color'] }},
			],
		});
	};
	
	onClosedChange = (e, value) => {
		if (e) e.preventDefault();
		this.page = 1;
		this.closed = value;
		this.init();
	}
	
	onResize = () => {
		this.getPerPage();
		this.init();
	};
	
	getPerPage = () => {
		let delta;
		if(window.innerWidth < 768) delta = 285;	
		else if(window.innerWidth < 992) delta = 225;
		else delta = 155;
		const cellHeight = window.innerWidth < 992 ? 80 : 65;
		this.perPage = Math.floor((this.innerHeight - delta) / cellHeight);
	};
	
	onPageChange = page => {
		this.page = page;
		this.init();
	};

	onUserChange = async user => {
		this.user = user;
		this.page = 1;
		this.init();
	}

	onOrderChange = async order => {
		this.order = order;
		this.page = 1;
		this.init();
	}

	onBoardListChange = async boardList => {
		this.boardList = boardList;
		if (boardList.closing) {
			this.onClosedChange(null, true);
		}
		else {
			this.onClosedChange(null, false);
		}
	}

	renderUser = user => user ? <User user={user} size={18}/> : '-';

	renderBoardList = boardList => <><span className="color" style={{ background: boardList.color }} /> {boardList.name}</>;
	
	render() {
		return <div className="fixed-page task-list">
			<div className="filters">
				<div className="closed-filter">
					<a href="#" onClick={e => this.onClosedChange(e, false)} className={this.closed === false ? 'active' : ''}>{t('task.active')}</a>&nbsp;|&nbsp;
					<a href="#" onClick={e => this.onClosedChange(e, true)} className={this.closed === true ? 'active' : ''}>{t('task.closed')}</a>&nbsp;|&nbsp;
					<a href="#" onClick={e => this.onClosedChange(e, null)} className={this.closed === null ? 'active' : ''}>{t('task.all')}</a>
				</div>
				<div className="user-filter">
					{t('task.assignedTo')}:&nbsp;
					<RecordSelect
						model={store.model.User}
						value={this.user}
						onChange={this.onUserChange}
						isRequired
						prependItems={[
							{ label: t('task.All'), value: ALL },
							{ label: t('task.notAssigned'), value: NONE },
						]}
						showValue={this.user === ALL ? t('task.All') : this.user === NONE ? t('task.notAssigned') : undefined}
						computed={this.renderUser}
						searchFields={['username', 'lastName', 'firstName', 'middleName']}
						filter={{ order: 'lastName asc, firstName asc, username asc' }}
						className="drop-down-sm"
					/>
				</div>
				<div className="boardlist-filter">
					{t('task.boardList')}:&nbsp;
					<RecordSelect
						model={store.model.BoardList}
						value={this.boardList}
						onChange={this.onBoardListChange}
						isRequired
						prependItems={[
							{ label: t('filter.all'), value: ALL },
						]}
						showValue={this.boardList === ALL ? t('filter.all') : undefined}
						computed={this.renderBoardList}
						searchFields={['name']}
						filter={{ order: 'name asc' }}
						className="drop-down-sm"
					/>
				</div>
				<div className="task-order">
					{t('sorting.title')}:&nbsp;
					<Select
						value={this.order}
						onChange={this.onOrderChange}
						isRequired
						noSearch
						items={[
							{ label: t('task.sortByUpdated'), value: 'updatedAt desc' },
							{ label: t('task.dueDate'), value: 'dueDate desc' },
							{ label: t('task.priority'), value: 'priority desc' },
						]}
						className="drop-down-sm"
					/>
				</div>
			</div>
			<Pager current={this.page} totalCount={this.records.totalCount} itemsPerPage={this.perPage} onChange={this.onPageChange} />
			<div className="fixed-page tasks">
				{this.records.map(task => <TaskItem key={task.id} task={task} />)}
			</div>
		</div>;
	}
	
}
