import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DeleteBtn from '!!svg-react-loader!client/img/delete-file-btn.svg';

import { getIconForExtension } from './icons';
import { formatDate } from 'client/tools';
import t from 'i18n';
import { Popconfirm } from '@smartplatform/ui';

@observer
export default class Attachment extends React.Component {
	static propTypes = {
		attachment: PropTypes.object,
		property: PropTypes.string,
		onDelete: PropTypes.func,
	};

	static defaultProps = {
		property: 'filename',
	};

	constructor(props) {
		super(props);
	}

	delete = async () => {
		// throw new Error('Thrown error!')
		const { attachment, property } = this.props;
		console.log('delete', attachment, property);
		this.props.onDelete && this.props.onDelete(attachment);
		if (!this.props.softDelete) {
			try {
				await attachment.deleteFile(property);
			} catch (e) {}
			await attachment.delete();
		}
	};

	render() {
		const { attachment, property, isNew } = this.props;

		const ext = attachment[property].split('.').slice(-1);
		const icon = getIconForExtension(ext);

		const msg = (
			<>
				{t('deleteFile')}
				<br />
				<em>{attachment[property]}</em>?
			</>
		);

		return (
			<div className='attachment'>
				<span className='icon'>{icon}</span>
				<div className='info'>
					<a className='file-name' target='_blank' href={attachment.downloadFile(property)}>
						{attachment[property]}
					</a>
					{!isNew && (
						<Popconfirm content={msg} onConfirm={this.delete}>
							<DeleteBtn />
						</Popconfirm>
					)}
					<div className='date'>{formatDate(attachment.createdAt, 'dd.MM.yyyy HH:mm')}</div>
				</div>
			</div>
		);
	}
}
