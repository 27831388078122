import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import isAfter from 'date-fns/isAfter';

import { Loader } from '@smartplatform/ui';
import stages from './stages';
import OrderLog from './OrderLog';
import { Comment, PostComment, Section } from 'components';
import Popup from './Popup';
import t from 'i18n';
import './style.scss';

@observer
export default class History extends React.Component {

	@observable status = stages.LOADING;
	@observable order = null;
	@observable error = null;
	@observable popup = null;
	@observable items = [];

	logs = [];
	comments = [];

	constructor(props) {
		super(props);
		this.props.instance && this.props.instance({ reload: this.reload });
		this.load(true);
	}

	load = async (full = false) => {
		if (this.status !== stages.LOADING) this.status = stages.RELOADING;
		try {
			const logs = await this.props.order.logs.find({
				include: [
					{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'client', scope: { fields: ['id', 'name'] } },
					{ relation: 'list', scope: { fields: ['id', 'name', 'color'] } },
				],
				order: 'createdAt asc',
				skip: full ? 0 : this.logs.length,
			});

			console.log('comments skip', this.comments.length);

			const comments = await this.props.order.comments.find({
				include: [
					{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'attachments' },
				],
				where: { type: null },
				order: 'createdAt asc',
				skip: full ? 0 : this.comments.length,
			});

			this.logs = full ? logs : [...this.logs, ...logs];
			this.comments = full ? comments : [...this.comments, ...comments];

			const items = [
				...this.logs.map((record, index) => ({ type: 'orderLog', record, index })),
				...this.comments.map((record, index) => ({ type: 'comment', record, index })),
			];
			items.sort((a, b) => isAfter(new Date(a.record.createdAt), new Date(b.record.createdAt)) ? 1 : -1);

			this.items = items;
			this.status = stages.DONE;
		}
		catch (e) {
			console.error(e);
			this.error = e.message;
			this.status = stages.ERROR;
		}
	};

	onSubmitComment = async () => {
		this.props.onChange && await this.props.onChange(['updatedAt']);
	};

	onCommentDelete = async (comment) => {
		const attachments = await comment.attachments();
		for (let attachment of attachments) {
			console.log('- deleting file:', attachment.filename);
			await comment.attachments.remove(attachment.id);
			await attachment.deleteFile('filename');
			await attachment.delete();
		}
		await comment.delete();
		this.load(true);
	};

	reload = async () => {
		this.load();
	};

	showDelta = (field, index) => {
		this.popup = {
			order: this.props.order,
			logs: this.logs,
			index,
			field,
			onClose: () => this.popup = null,
		};
	};

	render() {
		if (this.status === stages.LOADING) return <Loader />;
		if (this.status === stages.ERROR) return <div className="error">{this.error}</div>;

		const { order, canViewSum } = this.props;

		const logs = this.items.map(item => {
			const { type, record, index } = item;
			if (type === 'comment') {
				return <Comment key={'comment-' + record.id} comment={record} className="order-comment" onDelete={this.onCommentDelete} />;
			}
			if (type === 'orderLog') {
				return <OrderLog
					key={'orderlog-' + record.id}
					logs={this.logs}
					index={index}
					order={order}
					onShowPopup={this.showDelta}
					canViewSum={canViewSum}
				/>;
			}
			return <div key={'inknown-' + record.id} className="unknown-item">{type}, id: {record.id}</div>;
		});

		return <Section className="order-history">
			<div className="title">{t('order.history.title')} {/*<a href="#" onClick={this.reload}>reload</a>*/}</div>
			<div className="logs">
				{logs}
			</div>
			<PostComment record={order} onSubmit={this.onSubmitComment} />
			{this.popup && <Popup {...this.popup} />}
		</Section>;
	}

}
