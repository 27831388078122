import { Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Handbook } from './handbook';
import { Workshops } from "./workshops";
import { MaterialTypes } from "./material-types";

export const TechnicalRoadmap = () => {

	const path = '/technicalroadmap';

	const operations = path + "/operations";
	const equipments = path + "/equipments";
	const workshops = path + "/workshop"
	const materialTypes = path + "/materialtypes"
	const materials = path + "/materials"

	const workshopsTitle = t('process.workshop.plural')
	const equipmentsTitle = t('process.equipment')
	const operationsTitle = t('process.operation.plural')
	const materialTypesTitle = t('material.type.plural')
	const materialTitle = t('material.plural')

	const workshopsRelationTitle = t('process.workshop.title')
	const materialTypesRelationTitle = t('material.type.title')

	return <>
		<Route path={path} exact><Redirect to={workshops}/></Route>
		<Tabs className="technicalroadmap-tabs">
			<Tab path={workshops} title={workshopsTitle}>
				<Workshops path={workshops} modelName="ProcessWorkshop" title={workshopsTitle}/>
			</Tab>
			<Tab path={equipments} title={equipmentsTitle}>
				<Handbook path={equipments} modelName="ProcessEquipment" title={equipmentsTitle}
						  relationLabel={workshopsRelationTitle} relation={'workshop'}/>
			</Tab>
			<Tab path={operations} title={operationsTitle}>
				<Handbook path={operations} modelName="ProcessOperation" title={operationsTitle}
						  relationLabel={workshopsRelationTitle} relation={'workshop'}/>
			</Tab>
			<Tab path={materialTypes} title={materialTypesTitle}>
				<MaterialTypes path={materialTypes} modelName="MaterialType" title={materialTypesTitle}/>
			</Tab>
			<Tab path={materials} title={materialTitle}>
				<Handbook path={materials} modelName="Material" title={materialTitle}
						  relationLabel={materialTypesRelationTitle} relation={'materialType'}/>
			</Tab>
		</Tabs>
	</>
};
