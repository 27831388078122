import qs from 'qs';
import { createBrowserHistory } from 'history';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore, { THEMES } from './ui';
import { PROJECTS_MODE_LIST } from './pages/projects/Projects';
import { ORDERS_MODE_KANBAN } from './pages/orders/Orders';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {

	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui

	subscribers = {};

	constructor() {
		this.history = createBrowserHistory();
		this.history.listen(this.onHistoryChange);
		this.ui = new UIStore();

		this.local = new LocalStore();
		this.local.extend({
			ordersMode: ORDERS_MODE_KANBAN,
			projectsMode: PROJECTS_MODE_LIST,
			theme: THEMES[0],
		});
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	init = async () => {
		await this.model.connect();
		document.body.className = this.local.theme + '-theme';
	};

	subscribe = (event, callback) => {
		if (!this.subscribers[event]) this.subscribers[event] = [];
		this.subscribers[event].push(callback);
	};

	unsubscribe = (event, callback) => {
		if (this.subscribers[event]) {
			const index = this.subscribers[event].findIndex(cb => cb === callback);
			if (index !== -1) this.subscribers[event].splice(index, 1);
			if (this.subscribers[event].length === 0) delete this.subscribers[event];
		}
	};

	fireHMR = () => {
		// console.log('HMR!');
		if (this.subscribers.hmr) this.subscribers.hmr.forEach(cb => cb());
	};
	
	onHistoryChange = e => {
		// console.log('onHistoryChange', e);
		if (this.subscribers.history) this.subscribers.history.forEach(cb => cb(e));
	};

}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
