import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './task-edit';

export const Tasks = () => <Switch>
	<Route path="/tasks" exact component={List} />
	<Route path="/tasks/:id" component={Edit} />
</Switch>;
