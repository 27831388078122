import React from 'react';
import { Button } from '@smartplatform/ui';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import { ExportButton, ImportButton, ChartButton } from 'components/buttons';

@inject('store')
@observer
export class ActionBar extends React.Component {
	render() {
		const { store } = this.props;
		const { toggleProp, showCharts } = store;

		return (
			<div className='action-bar'>
				<Button variant='primary' onClick={toggleProp('showEditPopup')}>
					<b>{t('operation.add').toUpperCase()}</b>
				</Button>
				<ExportButton disabled />
				<ImportButton disabled />
				<ChartButton onClick={toggleProp('showCharts')} className={showCharts ? 'active' : undefined} />
			</div>
		);
	}
}
