import React from 'react';
import { observer } from 'mobx-react';
import { Field, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import { FIELDS, Suggestions } from 'components/contragents';

@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.setContragentId = this.setContragentId.bind(this);
	}

	setContragentId(record) {
		record['contragentId'] = this.props.contragentId;
		return record;
	}
	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);
		return (
			<div className='fixed-page contragent-form'>
				<ModelEdit
					beforeSave={this.setContragentId}
					model={store.model[modelName]}
					id={id}
					path={path}
					title='Банковские реквезиты'
				>
					<div className='row'>
						<div className='col-4'>
							<Field property={FIELDS.BANK} label='Наименование банка'>
								<Suggestions search='bank' />
							</Field>
							<Field property={FIELDS.BIC} label='БИК банка' />
							<Field property={FIELDS.SWIFT} label='SWIFT' />
							<Field property='checkingAccount' label='Рассчётный счёт' />
							<Field property={FIELDS.CORRESPONDENT_ACCOUNT} label='Корр. счёт' />
							<Field property='registrationNumber' label='Корр. счёт' />
							<Field relation='currency' property='name' label={t('currency.title')} />
							<Field property={FIELDS.ADDRESS} label='Адрес' />
						</div>
					</div>
				</ModelEdit>
			</div>
		);
	}
}
