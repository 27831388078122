import React from 'react';
import { Button, RecordSelect, Select } from '@smartplatform/ui';
import appStore from 'client/store';
import t from 'i18n';
import './style.scss';
import { PERIODS } from '../constants';
import { inject, observer } from 'mobx-react';
import { renderBankAccount } from 'client/tools';

@inject('store')
@observer
export class Toolbar extends React.Component {
	render() {
		const { store } = this.props;
		const { onChange, bankAccounts, period, cleanFilters } = store;
		const selectProps = { noTotal: true, maxItems: 10, itemsPerPage: 1000, width: 300, isRequired: true };

		const showBankAccountValue =
			bankAccounts.length > 0 ? (
				t('payment.selectedBankAccounts') + ': ' + bankAccounts.length
			) : (
				<span className='hint'>{t('payment.selectBankAccount')}</span>
			);

		return (
			<div className='toolbar'>
				<Select
					{...selectProps}
					key={period}
					items={PERIODS.map((name, i) => ({ value: name, label: t('datePeriod.' + name) }))}
					value={period}
					onChange={onChange('period')}
					showValue={period ? t('datePeriod.' + period) : t('datePeriod.select')}
					noSearch
				/>
				<RecordSelect
					{...selectProps}
					model={appStore.model.ViewBankAccount}
					onChange={onChange('bankAccounts')}
					value={bankAccounts}
					property='checkingAccount'
					showValue={showBankAccountValue}
					filter={{ where: { myCompany: true } }}
					computed={renderBankAccount}
				/>
				<Button onClick={cleanFilters} className='transparent'>
					{t('clean')}
				</Button>
			</div>
		);
	}
}
