import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComment } from '@fortawesome/free-solid-svg-icons';
import { Labels } from 'client/components';
import { UserAvatar } from '../members';
import { relative } from 'client/tools';
import './style.scss';

@observer
export default class TaskCard extends React.Component {

	render() {
		const { task } = this.props;

		return <>
			<div className="task-content">
				<div className="top">
					<div className={'id' + (task.closed ? ' closed' : '')}># {task.id} {task.closed && <FontAwesomeIcon icon={faCheck} />}</div>
					<div className={`right ${task.dueDate && new Date(task.dueDate) - new Date() - 86400000 < 0 && 'task-expired'}`}>
						{task.dueDate ? `Дедлайн: ${relative(task.dueDate)}` : relative(task.updatedAt)}
					</div>
				</div>
				<div className="main">
					<div className="name" dangerouslySetInnerHTML={{ __html: task.name.replace(/[\r\n]+/g, '<br />') }}/>
					{task.user && <div className="user evented">
						{/*<Popover content={task.fio || task.username || 'userId: ' + task.userId}><Avatar user={task.user} size={24} /></Popover>*/}
						<UserAvatar user={task.user} size={24} />
					</div>}
				</div>
				<div className="bottom">
					<div className="labels evented">
						<Labels record={task} size={8} />
					</div>
					<div className="info">
						{/*{task.hasDescription ? <FontAwesomeIcon icon={faAlignLeft} /> : null}*/}
						{task.commentsCount > 0 ? <><FontAwesomeIcon icon={faComment} /> {task.commentsCount}</> : null}
					</div>
				</div>
			</div>
		</>;
	}

}
