import { DndContext } from '@smartplatform/ui';
import 'bootstrap-4-grid/scss/grid.scss';
import { observer } from 'mobx-react';
import React from 'react';
import 'react-image-lightbox/style.css';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Layout from './layout';
import { Login, Register } from 'components';
import * as Pages from './pages';
import store from './store';
import './styles/main.scss';
import { Notification } from 'components'

const App = () => {
	if (!store.model.isInitialized) return null;
	if (!store.model.isAuthorized) {
		return <Router history={store.history}>
			<Switch>
				<Route path="/register" component={Register} />
				<Login />
			</Switch>
		</Router>;
	}

	// const className = classNames('project-vars', store.ui.theme + '-theme');
	const className = "project-vars";

	return (
		<DndContext className={className}>
			<Router history={store.history}>
				<Layout>
					<Notification />
					<Switch>
						<Route path='/' exact>
							<Redirect to='/tasks' />
						</Route>
						<Route path='/dashboard' component={Pages.Dashboard} />
						<Route path='/projects' component={Pages.Projects} />
						<Route path='/documents' component={Pages.Documents} />
						<Route path='/document-types' component={Pages.DocumentTypes} />
						<Route path='/document-status' component={Pages.DocumentStatus} />
						<Route path='/tasks' component={Pages.Tasks} />
						<Route path='/boardlists' component={Pages.BoardLists} />
						<Route path='/projectlists' component={Pages.ProjectLists} />
						<Route path='/projectroles' component={Pages.ProjectRoles} />
						<Route path='/labels' component={Pages.Labels} />
						<Route path='/clients' component={Pages.Clients} />
						<Route path='/contragents' component={Pages.Contragents} />
						<Route path='/persons' component={Pages.Persons} />
						<Route path='/budgets' component={Pages.Budgets} />
						<Route path='/budgetroles' component={Pages.BudgetRoles} />
						<Route path='/users' component={Pages.Users} />
						<Route path='/acls' component={Pages.ACLs} />
						<Route path='/roles' component={Pages.Roles} />
						<Route path='/rolemappings' component={Pages.RoleMappings} />
						<Route path='/profile' component={Pages.Profile} />
						<Route path='/test' component={Pages.Test} />
						<Route path='/features' component={Pages.Features} />
						<Route path='/pricecategories' component={Pages.PriceCategories} />
						<Route path='/orderlists' component={Pages.OrderLists} />
						<Route path='/orders' component={Pages.Orders} />
						<Route path='/ordersreport' component={Pages.Orders} />
						<Route path='/orderpositionroles' component={Pages.OrderPositionRoles} />
						<Route path='/orderpositionparameters' component={Pages.OrderPositionParameters} />
						<Route path='/technicalroadmap' component={Pages.TechnicalRoadmap} />
						{/*<Route path="/warehouses" component={Pages.Warehouses} />*/} {/* ? такого компонента нет */}
						<Route path='/1c-options' component={Pages.EdOptions} />
						<Route path='/1c-nodes' component={Pages.EdNodes} />
						<Route path='/1c-objects' component={Pages.EdObjects} />
						<Route path='/1c-history' component={Pages.EdHistory} />
						<Route path='/1c-journal' component={Pages.EdJournal} />
						<Route path='/nomenclature' component={Pages.Nomenclature} />
						<Route path='/nomenclature-balance-accounts' component={Pages.NomenclatureBalanceAccounts} />
						<Route path='/nomenclature-reserves' component={Pages.NomenclatureReserves} />
						<Route path='/contragent-types' component={Pages.ContragentTypes} />
						<Route path='/contracts' component={Pages.Contracts} />
						<Route path='/analytics' component={Pages.Analytics} />
						<Route path='/operations' component={Pages.Operations} />
					</Switch>
				</Layout>
			</Router>
			{/*<DevTools />*/}
		</DndContext>
	);
}

export default observer(App);
