import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import FormContext from "@smartplatform/ui/src/components/form/context";
import { observable } from "mobx";

export default class extends React.Component {
	static contextType = FormContext;
	@observable hiddenFields = []
	constructor(props) {
		super(props);
	}
	onCreate = () => {
		store.route.push({
			path: `${this.props?.match?.url}/create`
		});
	}
	render() {
		return(
			<div>
				<ModelList
					model={store.model[this.props.modelName]}
					title='Банковские реквезиты'
					filter={{
						where: { contragentId: this.props.contragentId }
					}}
				>
					<Column property="personName" label='ФИО' />
					<Column property='personRole' label='Должность' />
					<Column property='phone' label='Телефон' />
					<Column property='email' label='E-mail' />
				</ModelList>
			</div>
		);
	}
};
