import React from 'react';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Switch, Route, matchPath } from 'react-router-dom';
import { Tabs, Tab, Select, Loader } from '@smartplatform/ui';
import { Mobile, Desktop } from 'components';
import routes from './routes';

@observer
export default class extends React.Component {
	@observable route = null;
	@observable record = null;
	@observable load = true;
	constructor(props) {
		super(props);
		this.routes.forEach((route) => {
			const match = matchPath(store.route.path, route);
			if (match) this.route = route;
		});
		this.init();
	}
	get routes() {
		return routes(this.props.path, this.record);
	}

	init = async () => {
		this.load = true;
		const paramsId = this.props.match.params.id;
		if (paramsId === 'create') {
			this.record = new store.model[this.props.modelName]();
		} else {
			this.id = parseInt(this.props.match.params.id);
			this.record = await store.model[this.props.modelName].findById(this.id, {
				include: ['type', 'status', 'contragent', 'labels', 'user', 'attachments'],
			});
		}
		this.load = false;
	};
	onRouteChange = (route) => {
		this.route = route;
		store.route.push({ path: route.path.replace(/:documentId/, this.id) });
	};

	render() {
		const _routes = this.routes.filter((route) => (route.visible ? route.visible() : true));
		if (this.load) return <Loader />;
		if (!this.record) return <>Клиент не найден</>;
		return (
			<>
				<h1 style={{ marginBottom: 20 }}>Договор на оказание услуг</h1>
				<Desktop>
					<Tabs params={{ documentId: this.id }} className='documents-tabs-info'>
						{_routes.map((route, i) => (
							<Tab key={i} {...route} component={route.component} />
						))}
					</Tabs>
				</Desktop>
				<Mobile>
					<Select
						items={_routes.map((route) => ({ label: route.title, value: route }))}
						value={this.route}
						showValue={this.route.title}
						onChange={this.onRouteChange}
						className='route-filter'
						isRequired
						noSearch
					/>
					<Switch>
						{this.routes.map((route, i) => (
							<Route key={i} {...route} />
						))}
					</Switch>
				</Mobile>
			</>
		);
	}
}
