import React from 'react';
import { observer } from 'mobx-react';
import { Link, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import store from 'client/store';

@observer
export default class MenuItem extends React.Component {
	render() {
		const { item } = this.props;
		const { label, path, icon, reactIcon } = item;

		if (item.enabled && !item.enabled(store)) return null;

		const match = matchPath(document.location.pathname, { path });
		const isActive = match !== null;

		const className = classNames('item', { active: isActive });
		let renderIcon = <span className='no-icon' />;
		if (icon) renderIcon = <FontAwesomeIcon icon={icon} />;
		if (reactIcon) renderIcon = reactIcon;
		return (
			<Link to={path} className={className}>
				<span className='icon'>{renderIcon}</span>
				<div className='title'>{label}</div>
			</Link>
		);
	}
}
