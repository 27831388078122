import React from 'react';

import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';

export default (props) => {
	const id = parseInt(props.match.params.contractId);
    console.log("🚀 ~ file: Edit.js ~ line 10 ~ props.match.params", props.match.params)
	return (
		<ModelEdit model={store.model[props.modelName]} {...props} id={id} disabled noSave noDelete>
			<Field relation='organization' property='name' label={t('contract.organization')} />
			<Field relation='contragent' property='name' label={t('contract.contragent')} />
			{Object.keys(store.model[props.modelName].PROPERTIES).map(
				(property) =>
					property !== 'link' &&
					!['At', 'Id', 'id'].includes(property.slice(-2)) && (
						<Field key={property} property={property} label={t('contract.' + property)} />
					)
			)}
		</ModelEdit>
	);
};
