import store from 'client/store';
import { FIELDS, PERIODS } from './constants';
import t from 'i18n';
import { observable } from 'mobx';

export default class AnalyticsStore {
	@observable period = PERIODS[0];
	@observable startDate = null;
	@observable endDate = null;
	@observable bankAccounts = [];
	constructor() {}

	init = async () => {
		this.loadLocalStoreage();
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.saveToLocalStorage();
	};

	cleanFilters = () => {
		// FIELDS.forEach((field) => (this[field] = this[field] = null));
		this.period = PERIODS[0];
		this.bankAccounts = [];
		this.saveToLocalStorage();
	};

	saveToLocalStorage = () => {
		if (!store.local.analytics) store.local.analytics = {};
		FIELDS.forEach((field) => (store.local.analytics[field] = this[field]));
		store.local.save();
	};
	loadLocalStoreage = () => {
		const storageKeys = Object.keys(store.local.analytics || {});
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				const storageValue = store.local.analytics[key];
				if (key === 'cashFlows' && !storageValue) {
					this[key] = [];
				} else if (key === 'period' && !storageValue) {
					this[key] = PERIODS[0];
				} else this[key] = storageValue;
			});
	};
}
