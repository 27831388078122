import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import './style.scss';

export default class AddButton extends React.Component {

	static propTypes = {
		onClick: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		disabled: false,
	};

	render() {
		const { onClick, disabled } = this.props;
		const className = classNames('add-row', { disabled });

		return <div className={className}>
			<AddIcon onClick={onClick} className="add-btn" />
		</div>;
	}

}
