import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import DeleteIcon from '!!svg-react-loader!client/img/delete-btn.svg';
import { Popconfirm } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

const DeleteButton = props => {
	const { children, confirmMessage, onConfirm, portalClassName } = props;

	return <Popconfirm className="delete-btn" content={confirmMessage} onConfirm={onConfirm} portalClassName={portalClassName}>
		<DeleteIcon />
	</Popconfirm>;
}

DeleteButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.any,
	portalClassName: PropTypes.string,
};

DeleteButton.defaultProps = {
	confirmMessage: t('delete'),
};

export default DeleteButton;
