import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Pager, Loader } from '@smartplatform/ui';
import { WrappedTable } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import { checkModelAccess } from 'client/tools/checkModelAccess';

@withRouter
@observer
export default class List extends React.Component {

	static propTypes = {
		model: PropTypes.any,
		title: PropTypes.any,
		filter: PropTypes.object,
		path: PropTypes.string,
		order: PropTypes.string,
		properties: PropTypes.object, // предустановленные данные для новой записи
		disableFilters: PropTypes.bool,
		disableCreate: PropTypes.bool,
		afterPager: PropTypes.object,
		externalSearch: PropTypes.bool,
	};

	static defaultProps = {
		order: 'id desc',
	};

	@observable query = {};
	@observable records = [];
	@observable isLoading = false;
	@observable page = 1;
	@observable search = '';
	@observable error = null;

	initial = true;
	reloads = 0;

	constructor(props) {
		super(props);
		if (props.title) store.ui.title = props.title;

		if (!store.local.itemsPerPage) {
			store.local.extend({ itemsPerPage: 10 });
			store.local.save();
		}

		this.error = checkModelAccess(this.props.model.INFO.name);

		if (!this.error) {
			this.page = this.getPage();
			this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
			this.query = {
				...this.props.filter,
				skip: (this.page - 1) * store.local.itemsPerPage,
				limit: store.local.itemsPerPage,
				order: props.order,
			};
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const page = this.getPage();
		if (JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)) {
			this.query = {
				...this.query,
				...this.props.filter,
			};
			this.doSearch();
		}
		if (page !== this.page) {
			this.page = page;
			this.query = {
				...this.query,
				skip: (page - 1) * store.local.itemsPerPage,
				limit: store.local.itemsPerPage,
			};
		}
	}

	getPage = () => (this.props.match && this.props.match.params ? parseInt(this.props.match.params.page) || 1 : 1);

	onPageChange = (newPage, forceReload) => {
		if (forceReload) {
			console.log('reload!');
			this.reload();
		}
		else {
			const { page, ...params } = this.props.match.params;
			let pattern = this.props.match.path;
			if (!page && newPage !== 1) pattern += (this.props.path || '') + '/page/:page';
			// if (newPage === 1) pattern = pattern.replace(/\/page\/:page/, '');
			let url = generatePath(pattern, {
				...params,
				page: newPage, //newPage > 1 ? newPage : undefined,
			});
			store.route.push({ path: url });
		}
	};

	create = () => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/create';
		let path = generatePath(pattern, { ...params });
		const route = {
			path,
			// params: this.props.properties,
		};
		store.route.push(route);
	};

	onRowClick = (record) => {
		store.route.push({ path: this.makePath(record) });
	};

	makePath = (record) => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/:id';
		let path = generatePath(pattern, {
			...params,
			id: record.id,
		});
		return path;
	};

	onQueryUpdate = (query, prevQuery) => {
		// console.log(this.initial, 'initial');
		this.initial = false;
		// console.log('onQueryUpdate', query);
		this.query = query;
		if (prevQuery && this.page > 0 && query.skip === 0) {
			const { page, ...params } = this.props.match.params;
			let pattern = this.props.match.path;
			pattern = pattern.replace(/\/page\/:page/, '');
			let path = generatePath(pattern, { ...params });
			store.route.push({ path });
		}
	};

	onLoadStart = () => (this.isLoading = true);
	onLoadEnd = () => (this.isLoading = false);

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		const trimmed = this.search.trim();
		const { _totalCount, ...query } = this.query;
		const { externalSearch } = this.props;
		this.page = 1;
		if (externalSearch) {
			this.query = {
				...query,
				skip: 0,
			};
		} else {
			this.query = {
				...query,
				search: trimmed.length > 0 ? trimmed : undefined,
				skip: 0,
			};
		}
	};

	reload = () => {
		this.query = {
			...this.query,
			limit: store.local.itemsPerPage,
			__reloads: ++this.reloads
		};
	};

	onPageSizeChange = (value) => {
		store.local.itemsPerPage = value;
		store.local.save();
	};

	render() {
		if (this.error) return <div className='error'>{this.error}</div>;
		const { children, model, path, match, afterPager } = this.props;
		const className = classNames('table-with-pager', {
			loading: this.isLoading,
			initial: this.isLoading && this.initial,
		});

		// console.log('list render', className);

		return (
			<div className='fixed-page model-list'>
				{!this.props.disableFilters ? (
					<div className='filters'>
						<input type='text' value={this.search} onChange={this.onSearch} placeholder={t('search')} />
						{!this.props.disableCreate ? (
							<Button onClick={this.create} variant='primary'>
								Создать
							</Button>
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
				{this.isLoading && this.initial && <Loader />}
				<div className={className}>
					<div className='d-flex align-items-center'>
						<Pager
							current={this.page}
							totalCount={this.query._totalCount || 0}
							onChange={this.onPageChange}
							itemsPerPage={store.local.itemsPerPage}
							pageSizeFunc={this.onPageSizeChange}
						/>
						{afterPager}
					</div>

					<WrappedTable
						model={model}
						query={this.query}
						onQueryUpdate={this.onQueryUpdate}
						onRowClick={this.onRowClick}
						onLoadStart={this.onLoadStart}
						onLoadEnd={this.onLoadEnd}
					>
						{children}
					</WrappedTable>
				</div>
			</div>
		);
	}
}
