import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Avatar, Popover } from '@smartplatform/ui';
import { fio } from 'client/tools';
import pStore from '../store';

@observer
export default class MemberCard extends React.Component {

	constructor(props) {
		super(props);
	}

	onClick = async () => {
		// this.props.onMemberClick && this.props.onMemberClick(this.props.member);
		pStore.user = pStore.user && pStore.user === this.props.member.user.id ? null : this.props.member.user.id;
		const id = parseInt(this.props.member.projectId);
		if (id) await pStore.load(id);
		if (pStore.kanban) {
			pStore.kanban.reload();
		}
	};

	render() {
		const { member } = this.props;

		const content = <span className="member-popover">
			{fio(member.user)}
			{member.role && <em>{member.role.name}</em>}
		</span>;

		const className = classNames('member-minicard', {
			selected: pStore.user === member.user.id,
		});

		return <Popover className={className} onClick={this.onClick} content={content}>
			<Avatar user={member.user} size={22} badge={member.user.tasks().length > 0 ? member.user.tasks().length : undefined} />
		</Popover>;
	}

}
