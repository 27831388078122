import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { RecordSelect, Checkbox, DatePicker, Select } from '@smartplatform/ui';
import { Attachments, LabelsEdit, DeleteButton } from 'components';
import { DEFAULT_PRIORITY } from '../../../projects/TasksBoard';
import { getRoleNames } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class SideBar extends React.Component {

	@observable roles = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.roles = await getRoleNames();
	};
	
	onBoardListChange = async boardList => {
		const topTask = await store.model.Task.find({
			where: { boardListId: boardList.id },
			fields: ['id', 'priority'],
			order: 'priority desc',
			limit: 1,
		});
		this.props.task.priority = topTask.length > 0 ? topTask.priority * 2 : DEFAULT_PRIORITY;
		this.props.task.boardList = boardList;
		this.props.task.closed = boardList.closing;
		if (boardList.closing) this.props.task.closedAt = new Date();
		this.props.onChange && await this.props.onChange(['boardListId', 'priority', 'closed', 'closedAt']);
	};
	
	onStartDateChange = async date => {
		this.props.task.startDate = date;
		this.props.onChange && await this.props.onChange(['startDate']);
	};
	
	onDueDateChange = async date => {
		this.props.task.dueDate = date;
		this.props.onChange && await this.props.onChange(['dueDate']);
	};
	
	onPrivateChange = async value => {
		this.props.task.private = value;
		this.props.onChange && await this.props.onChange(['private']);
	};

	onAttachmentsChange = async () => {
		this.props.onChange && await this.props.onChange(['updatedAt']);
	};

	renderBoardList = boardList => <><span className="color" style={{ background: boardList.color }} /> {boardList.name}</>;

	delete = async () => {
		await this.props.task.delete();
		store.route.push({ path: `/projects/${this.props.task.projectId}` });
	};
	
	render() {
		const { task } = this.props;

		const listsIds = task.project ? task.project.lists().map(list => list.id) : [];
		
		return <div className="task-sidebar">
			<div className="sidebar-content">
				<div className="block">
					<label>{t('project.title')}</label>
					{task.project ?
						<Link to={`/projects/${task.projectId}`}>{task.project.name}</Link>
						:
						<div className="error"><FontAwesomeIcon icon={faExclamationTriangle} /> {t('task.noProject')}</div>
					}
				</div>
				<div className="block">
					<label>{t('task.boardList')}</label>
					<RecordSelect
						model={store.model.BoardList}
						value={task.boardList}
						onChange={this.onBoardListChange}
						computed={this.renderBoardList}
						searchFields={['name']}
						filter={{ where: { id: { inq: listsIds }}, order: 'priority desc'}}
						isRequired
						width="100%"
					/>
				</div>
				<div className="block">
					<label>{t('task.startDate')}</label>
					<DatePicker value={task.startDate} onChange={this.onStartDateChange} showTimeSelect={true} />
				</div>
				<div className="block">
					<label>{t('task.dueDate')}</label>
					<DatePicker value={task.dueDate} onChange={this.onDueDateChange} showTimeSelect={false} format="dd.MM.yyyy" />
				</div>
				<div className="block">
					<label>{t('label.plural')}</label>
					<LabelsEdit record={task} />
				</div>
				<div className="block">
					<Checkbox value={task.private} onChange={this.onPrivateChange} label={t('task.private')} />
				</div>
				<div className="block">
					<Attachments record={task} canUpload={true} onChange={this.onAttachmentsChange} />
				</div>
				{(store.model.user.id === task.ownerId || this.roles.includes('admin')) && <div className="block task-delete">
					<DeleteButton onConfirm={this.delete} confirmMessage={t('task.confirmDelete')}/>
				</div>}
			</div>
		</div>;
	}
	
}
